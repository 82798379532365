.navbar {
  background-color: #b25634;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  z-index: 100;
  width: 100%;
}
.red_notif {
  left: 16px;
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  color: #b25634;
  font-size: 10px;
  top: 25px;
  background-color: white;
}
.logwrap {
  width: wrap;
  left: 0;
}
.nav-buttonsign {
  display: flex;
  align-items: center;
}
.menu-bars {
  font-size: 2rem;
  background: none;
  margin-right: 30px;
  z-index: 10;
}
.icons_nav {
  color: #e6dcd7;
  height: 25px;
}
.icons_cross {
  height: 40px;
  padding-left: 10px;
  color: #e6dcd7;
}
.nav-menu {
  background-color: #e6dcd7;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 999;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  overflow-y: auto;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  /* transition: 350ms; */
}
.notif {
  background-color: #dbbfb1;
  width: 200px;
  display: none;
  height: wrap;
  justify-content: center;
  position: fixed;
  margin-top: 80px;
  margin-right: 0px;
  top: -100%;
  z-index: 100;
  right: 0;
}
.notif_link {
  text-decoration: none;
}
.outsidewrap {
  padding: 13px;
}
.notif_title {
  font-weight: 700;
  color: #745140;
  font-size: 18px;
  padding: 5px;
}
.notif_content {
  font-weight: 400;
  padding: 5px;
  color: #745140;
  font-size: 12px;
}
.notif.active {
  right: 0;
  top: 0;
  display: flex;
  z-index: 100;
  margin-top: 80px;
  margin-right: 0px;
  background-color: #dbbfb1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 0px 5px 6px;
  list-style: none;
  height: 60px;
}
.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #b17b67;
}

.nav-menu-items {
  width: 100%;
}
.navbar-toggle {
  background-color: #c49483;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.navwords {
  margin-left: 16px;
  font-size: 15px;
  color: #efe9e5;
}
.nav-m {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
}
@media screen and (max-width: 1080px) {
  #avatar {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-m {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    text-decoration: none;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-m.active {
    background: #e1d7d2;
    left: 0;
    justify-content: center;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #e6dcd7;
    color: #e1d7d2;
    text-decoration: none;
    border-radius: 0;
  }

  .navbar-logo {
    margin-left: 20px;
    top: 0;
    text-align: start;
    white-space: nowrap;
    position: relative;
    align-self: flex-start;
    transform: translate(20%, 50%);
  }

  .navbar-logo-notlogged {
    margin-left: 20px;
    position: relative;
    white-space: nowrap;
    top: 0;
    text-align: start;
    align-self: flex-start;
    transform: translate(20%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #e6dcd7;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #e6dcd7;
    padding: 14px 20px;
    border: 1px solid #e6dcd7;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #e6dcd7;
    color: #e1d7d2;
    text-decoration: none;
    transition: 250ms;
  }
}

/* here */

.navbar-container {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-grow: 1;
  margin-right: 30px;
  height: 80px;
  width: wrap;
}
path {
  stroke: #f5f5f5;
}
.navbar-container-logged {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 80px;
  width: wrap;
  flex-grow: 1;
}
.navbar-logo {
  color: #e6dcd7;
  margin-left: 20px;
  top: 0;
  text-align: start;
  white-space: nowrap;
  left: 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}
.wrap {
  cursor: pointer;
}
.wrap:hover {
  transition: 350ms;
  background-color: #e6dcd7;
}
.navbar-logo-notlogged {
  color: #e6dcd7;
  margin-left: 20px;
  top: 0;
  text-align: start;
  left: 0;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-item {
  height: 80px;
}
#avatar {
  height: 80px;
  color: #e6dcd7;
}
#avatar:hover {
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.nav-links {
  color: #e6dcd7;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #e6dcd7;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #e6dcd7;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  margin-right: 20px;
  display: none;
}

.menu-bell {
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: none;
  z-index: 10;
  color: #e6dcd7;
  stroke: #e6dcd7;
  cursor: pointer;
}

.icons_nav {
  color: #e6dcd7;
  stroke: #e6dcd7;
  border: #e6dcd7;
}
