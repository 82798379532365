.all {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #e6dcd7;
  flex-direction: row;
  align-items: center;
}

.languagereg {
  position: absolute;
  padding: 20px;
  top: 0;
  text-decoration: none;
  color: #745140;
  right: 70px;
  cursor: pointer;
  display: flex;
  flex: row;
}
#registeredsucc {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 10px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

#return {
  background-color: whitesmoke;
  text-decoration: underline;
  color: #b25634;
  font-size: 13px;
  width: 100%;
  text-align: center;
  padding: 10px;
  padding-bottom: 20px;
}
.bar {
  width: 100px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #b25634;
}
.popup {
  display: none;
}
.dot {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin: 10px;
  border: 2px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000000;
  z-index: 300;
}
.innerdot {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  background-color: #ffffff;
}
.role {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.signintext {
  position: absolute;
  padding: 20px;
  top: 0;
  text-decoration: underline;
  color: #b25634;
  right: 0;
  cursor: pointer;
}
.regsub {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.reg_next_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 50px;
  align-items: flex-end;
  justify-content: right;
  margin-bottom: 50px;
}
.role_full {
  display: flex;
  background: #e6dcd7;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}
.full {
  display: flex;
  background: #e6dcd7;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0;
  width: 100%;
  justify-content: center;
  height: 100%;
}
.btn-role {
  font-family: 'Lora';
  width: 240px;
  height: 60px;
  margin-bottom: 0px;
  margin-right: 25px;
  margin-top: 25px;
  margin-left: 25px;
  cursor: pointer;
  background-color: #00000000;
  color: #b25634;
  letter-spacing: 0.5px;
  font-size: 25px;
  border: 1px solid #b25634;
  padding: 8px 20px;
}
.pic_reg {
  width: 25vw;
}
.regall {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-role:hover {
  background: #b25634;
  opacity: 0.5;
  color: rgba(239, 233, 229, 1);
}
.btn-role-selected {
  background: #b25634;
  color: #efe9e5;
  font-family: 'Lora';
  width: 240px;
  height: 60px;
  margin-bottom: 0px;
  margin-right: 25px;
  margin-top: 25px;
  margin-left: 25px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 25px;
  border: 1px solid #b25634;
  padding: 8px 20px;
}
.title_role {
  color: #745140;
  font-weight: 100;
  font-size: 30px;
}
.title_reg {
  color: #745140;
  font-weight: 100;
  font-size: 30px;
}
.warning_role {
  color: red;
  font-size: 17px;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 10px;
}
.regwords {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.warning {
  color: red;
  font-size: 17px;
  font-weight: 100;
  margin-top: 10px;
}
.emailwarning {
  color: red;
  font-size: 15px;
  font-weight: 100;
  margin-top: 10px;
  text-align: start;
}
.next {
  font-family: 'Lora';
  width: 150px;
  height: 45px;
  background-color: #00000000;
  color: #b25634;
  letter-spacing: 0.5px;
  padding: 10 20px;
  font-size: 20px;
  border: 1px solid #b25634;
  cursor: pointer;
}
.next:hover {
  transition: all 0.3s ease-out;
  background: #b25634;
  color: #efe9e5;
  border-color: #b25634;
  transition: 250ms;
}
.back {
  font-family: 'Lora';
  width: 150px;
  height: 45px;
  background-color: #00000000;
  color: #b25634;
  padding: 10 20px;
  letter-spacing: 0.5px;
  font-size: 20px;
  border: 1px solid #b25634;
  cursor: pointer;
}
.back:hover {
  transition: all 0.3s ease-out;
  background: #b25634;
  color: #efe9e5;
  border-color: #b25634;
  transition: 250ms;
}
.getstarted {
  margin-top: 100px;
}
.reg {
  margin-left: 0px;
}
.register_input::placeholder {
  opacity: 0.3;
  color: #b25634;
}
.reggroup:focus-within ::placeholder {
  color: #b25634;
  opacity: 0.5;
}
.btn_reg {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.register_selector {
  width: 70vw;
  color: #b25634;
  letter-spacing: 2px;
  font-weight: 400;
  margin: 10px;
  font-size: 20px;
  border: none;
}
.menu {
  letter-spacing: 2px;
}
.selectplace {
  color: rgba(178, 86, 52, 0.3);
  letter-spacing: 2px;
}
.select {
  border-color: rgba(178, 86, 52, 0.3);
}
.register_input {
  width: 70vw;
  padding: 10px 10px;
  color: #b25634;
  letter-spacing: 2px;
  font-weight: 400;
  margin: 10px;
  font-size: 20px;
  border: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.regleft {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 7vw;
}
.regright {
  padding-left: 7vw;
}

.register_input:focus {
  border: none;
  color: #b25634;
  outline: none;
  opacity: 0.9;
  border-bottom: 1.5px solid #b25634;
}
.basic_input {
  margin-top: 25px;
  width: 700px;
  color: #b25634;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 20px;
  border: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.basic_input:focus {
  border: none;
  color: #b25634;
  outline: none;
  opacity: 0.9;
  border-bottom: 1.5px solid #b25634;
}

.basic_input::placeholder {
  font-weight: 400;
}
#reset_reg {
  border: none;
  cursor: pointer;
  z-index: 20;
}

#clear_reg {
  font-size: 13px;
  opacity: 0.5;
  z-index: 20;
  color: #b25634;
  margin-left: -20px;
}
#showpass_reg {
  font-size: 17px;
  z-index: 20;
  color: #b25634;
  margin-left: -20px;
}
#show_reg {
  border: none;
  z-index: 20;
  opacity: 0.7;
  cursor: pointer;
}
#clear_reg:hover {
  opacity: 1;
}
#show_reg:hover {
  opacity: 1;
}
.birthdayreggroup {
  padding: 10px;
}
.birthdayreggroup:focus-within ::placeholder {
  color: #b25634;
  opacity: 0.5;
}
@media screen and (max-width: 780px) {
  .regleft {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .btn-role {
    padding: 8px 20px;
    margin-bottom: 0px;
  }
  .regright {
    display: none;
    width: 0;
  }
  .full {
    padding-left: 100px;
    padding-right: 100px;
  }
  .register_input {
    width: 80vw;
  }

  .register_selector {
    width: 80vw;
  }
  .bar {
    display: none;
  }
}

@media screen and (max-width: 1260px) {
  .btn-role {
    padding: 8px 20px;
    margin-bottom: 0px;
  }
}
