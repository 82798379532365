.log {
  margin-top: 80px;
  display: flex;
  margin: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 100vh;
  z-index: 0;
  background-color: #e6dcd7;
}
.image_wrap_sign {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wordslog {
  align-items: flex-start;
  text-align: start;
  cursor: pointer;
  color: #745140;
}
.languagelog {
  margin: 0;
  top: 95px;
  right: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.signinglanguage {
  margin: 0;
  top: 95px;
  right: 15px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.pic_sign {
  padding: 30px;
  height: 70%;
}
.outerwrap_sign {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login_box {
  flex-direction: column;
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.title {
  color: #745140;
  font-size: 40px;
  font-weight: 400;
  margin-top: 130px;
  margin-bottom: 5px;
}
#signin_input {
  margin-top: 10px;
  padding: 10px 20px;
  height: 40px;
  padding-left: 30px;
  color: #b25634;
  width: 80%;
  font-weight: 600;
  letter-spacing: 0.8px;
  border: none;
  font-size: 20px;
  padding-top: 50px;
  padding-bottom: 20px;
  border-bottom: 1.5px solid rgb(178, 86, 52, 0.5);
}
#signin_input:focus {
  border: none;
  color: #b25634;
  outline: none;
  border-bottom: 1.5px solid rgb(178, 86, 52, 1);
}
#sigin_input_pass {
  padding: 10px 20px;
  padding-top: 50px;
  padding-left: 30px;
  width: 80%;
  padding-bottom: 20px;
  height: 40px;
  margin-top: 10px;
  font-size: 20px;
  color: #b25634;
  letter-spacing: 0.8px;
  font-weight: 600;
  border: none;
  border-bottom: 1.5px solid rgb(178, 86, 52, 0.5);
}
#sigin_input_pass:focus {
  border: none;
  outline: none;
  color: #b25634;
  border-bottom: 1.5px solid rgb(178, 86, 52, 1);
}
#signin_input::placeholder {
  color: #b25634;
  font-weight: 700;
  font-size: 20px;
  opacity: 0.5;
}
#sigin_input_pass::placeholder {
  color: #b25634;
  font-weight: 700;
  font-size: 20px;
  opacity: 0.5;
}
.formgroup {
  width: 450px;
  margin-left: 30px;
}
.formgroup i {
  position: relative;
  left: 0;
  top: 0px;
  width: 10px;
  font-size: 25px;
  padding-left: 15px;
  color: #b25634;
  opacity: 0.5;
}

.formgroup:focus-within #lock {
  opacity: 1;
  color: #b25634;
}
.formgroup:focus-within #user {
  opacity: 1;
  color: #b25634;
}
.formgroup:focus-within ::placeholder {
  opacity: 0.8;
  color: #b25634;
}

.btn-login {
  width: 170px;
  margin-top: 70px;
  border-radius: 3px;
  background-color: #b25634;
  padding: 8px 20px;
  font-size: 20px;
  color: #e6dcd7;
  border: 1px solid transparent;
  font-family: 'Lora';
}
.btn-loginpressed {
  width: 170px;
  margin-top: 70px;
  border-radius: 3px;
  background: #e6dcd7;
  padding: 8px 20px;
  font-size: 20px;
  color: #b25634;
  border: 1px solid #b25634;
  font-family: 'Lora';
  cursor: pointer;
}

.btn-login:hover {
  transition: all 0.3s ease-out;
  background: #e6dcd7;
  color: #b25634;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
.signUp {
  color: #b17b67;
  font-size: 15px;
  text-decoration: underline;
  margin-top: 30px;
  font-weight: 100;
}
.loginstat {
  color: #d92916;
  font-size: 15px;
  font-weight: 100;
  margin-bottom: 5px;
}

#showpass {
  font-weight: 100;
  font-size: 18px;
  opacity: 0.7;
  margin-left: 10px;
  color: #b25634;
}
#show {
  border: none;
}

#reset {
  border: none;
}
#clear {
  font-weight: 100;
  font-size: 15px;
  margin-left: 10px;
  opacity: 0.7;
  color: #b25634;
}
#clear:hover {
  opacity: 1;
  cursor: pointer;
}
#showpass:hover {
  cursor: pointer;
  opacity: 1;
}

@media screen and (max-width: 1260px) {
  .log {
    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }
  .image_wrap_sign {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pic_sign {
    height: 60%;
  }

  .login_box {
    flex-direction: column;
    display: flex;
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .image_wrap_sign {
    width: 0px;
    display: none;
  }
  .login_box {
    width: 100%;
    padding: 10;
  }
}
