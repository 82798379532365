.admin_wrap {
  width: 100%;
  height: wrap;
  padding: 50px;
  background: white;
}
.admin_title {
  text-align: center;
  color: black;
  font-size: 30px;
  margin-top: 30px;
}

.backtosignin {
  width: 100%;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
}
.subtitle {
  text-align: center;
  color: black;
  font-size: 20px;
  margin-top: 30px;
}

.content_admin {
  border: 1.5px solid lightgray;
  border-radius: 5px;
  padding: 5px;
}
.content_pair {
  cursor: pointer;
  border: 1.5px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  transition: 150ms;
}
.content_pair:hover {
  background-color: lightgray;
}
.admin_chart_pair {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}
.chart_pair {
  margin-top: 30px;
}
.admin_notif {
  font-size: 15px;
  width: 100%;
  margin-top: 10px;
  text-align: left;
  color: gray;
}
.admin_dialog {
  border-radius: 10px;
}
.admin_dialog_text {
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  color: black;
  font-size: 20px;
}
.admin_dialog_wrap {
  padding: 20px;
}
