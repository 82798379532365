.out {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 80px;
  height: 100%;
}
.unsentmsg {
  border-radius: 10px;
  color: whitesmoke;
  padding: 5px;
  width: min-content;
  white-space: nowrap;
  padding-left: 15px;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  padding-right: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}
.warningmsg {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #745140;
  font-weight: 700;
  font-size: 25px;
  padding: 20px;
}
.chathistory {
  background-color: #e1d7d2;
  width: 30%;
  display: flex;
  flex-direction: column;
}
.searchpad {
  padding: 20px;
}
.search {
  height: 50px;
  background: #b17b67;
  text-align: left;
  padding-left: 20px;
  border-radius: 5px;
  line-height: 50px;
  font-size: 16px;
  margin: 0;
  color: #efe9e5;
}
.searchicon {
  font-size: 150%;
  margin-right: 8px;
  height: 50px;
  float: right;
}

.peoplelist {
  height: calc(100vh-50px);
}
.chatcontent {
  background-color: #d8ccc7;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatname {
  width: 70%;
  height: 60px;
  text-align: left;
  padding-left: 20px;
  line-height: 60px;
  right: 0;
  color: #745140;
  position: absolute;
  font-size: 18px;
  background-color: rgb(225, 215, 210, 0.5);
}
.chatwraps {
  height: calc(100vh - 260px);
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 120px;
}
.chat {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 260px);
  width: 100%;
  padding: 15px;
  overflow-y: auto;
}

.send {
  height: 120px;
  width: 70%;
  position: absolute;
  bottom: 0;
  padding-top: 15px;
  color: #b17b67;
  font-size: 15px;
  right: 0;
  padding-left: 20px;
  background-color: rgb(225, 215, 210, 0.5);
}
.outerbox {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
}
.shadowing {
  padding-bottom: 25px;
  padding-top: 25px;
}
.shadowing:hover {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.05);
}
.imagemsg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #d6b7a8;
}
.msg_icon {
  font-size: 20px;
  color: #fdfaf1;
}
.infoboxmsg {
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  align-items: left;
}

.namemsg {
  font-size: 15px;
  color: #745140;
}
.latestmsg {
  margin-top: 10px;
  font-size: 13px;
  color: #c49483;
}
.numbermsg {
  background-color: #b17b67;
  height: 20px;
  align-self: right;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  color: #d8ccc7;
}
.align {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}

.messagesend {
  color: #745140;
  height: 100%;
  padding: 7px 0px 7px;
  font-size: 15px;
  border: none;
}
.messagesend::placeholder {
  color: #b25634;
  opacity: 0.7;
}
.messagesend:focus {
  padding: 0;
  color: #745140;
  padding: 7px 0px 7px;
  font-size: 15px;
  text-align: start;
  outline: none;
  border: none;
}
.chatava {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d6b7a8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.student_msg {
  height: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
}
.words_student {
  background-color: #c49483;
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px;
  margin-left: 10px;
  border-radius: 7px;
  color: #fdfafe;
}
.words {
  background-color: #d6b7a8;
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px;
  border-radius: 7px;
  color: #745140;
}
.sendword {
  font-family: 'Lora';
  position: fixed;
  bottom: 20px;
  right: 40px;
  color: #b25634;
  letter-spacing: 0.5px;
  font-size: 15px;
  border: none;
  cursor: pointer;
}
.contextdropdownmenu {
  width: 83px;
  position: absolute;
  background-color: #fdfafe;
  padding: 10px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  cursor: pointer;
  color: #b17b67;
  font-size: 15px;
  transition: 150ms;
  text-align: left;
}
.contextdropdownmenu:hover {
  background-color: whitesmoke;
}
.msg_time {
  margin-right: 15px;
  color: gray;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.msg_time_student {
  margin-left: 15px;
  color: gray;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.teacher_msg {
  height: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: flex-end;
}
@media screen and (max-width: 900px) {
  .chathistory {
    width: 0;
    display: none;
  }
  .chatcontent {
    width: 100%;
  }
  .chatname {
    width: 100%;
  }
  .send {
    width: 100%;
  }
}
