* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lora';
  background-color: #00000000;
}

.home {
  color: #e6dcd7;
}
