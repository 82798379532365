@import url(https://fonts.googleapis.com/css?family=Lora);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lora';
  background-color: #00000000;
}

.home {
  color: #e6dcd7;
}

body {
  font-family: 'Lora';
}

#homePageTitle {
	letter-spacing: 8px;
	text-align: center;
	color: #b17b67;
	font-size: 60px;
	align-self: center;
}

.loggedincontainer {
	height: 90vh;
	width: wrap;
	display: flex;
	background-color: #efe9e5;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 80px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: auto;
	object-fit: contain;
}

.btn-hero:hover {
	background: #b17b67;
	color: #efe9e5;
	transition: 250ms;
}

.btn-hero {
	color: #b17b67;
	font-family: 'Lora';
	width: 240px;
	text-decoration: none;
	margin: 10px;
	cursor: pointer;
	letter-spacing: 0.5px;
	font-size: 20px;
	text-align: center;
	border: 1px solid #b17b67;
	padding: 8px 20px;
}

#loggedintitle {
	letter-spacing: 10px;
	text-align: center;
	font-size: 50px;
	color: #b17b67;
	align-self: center;
}

#loggedinsub {
	padding-right: 25px;
	padding-left: 25px;
	font-size: 20px;
	color: #745140;
	margin-top: 50px;
	letter-spacing: 1px;
	text-align: center;
}

.btn-mobile {
	display: flex;
	text-decoration: none;
}

.pic_hero {
	width: 30vw;
}

#homePageSubTitle {
	letter-spacing: 1px;
	text-align: center;
	color: #745140;
	align-self: center;
	font-size: 20px;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 20px;
}

.hero-container {
	height: 90vh;
	width: wrap;
	display: flex;
	background-color: #efe9e5;
	flex-direction: row;
	margin-top: 80px;
}

.left_hero {
	width: 40%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.right_hero {
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.hero-btns {
	margin-top: 25px;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.hero-btns .btn-hero {
	margin: 20px;
	width: 200px;
	text-align: center;
	font-size: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 60px;
}

@media screen and (max-width: 960px) {
	#homePageSubTitle {
		font-size: 18px;
		line-height: 25px;
	}

	#homePageTitle {
		font-size: 30px;
		letter-spacing: 8px;
	}

	#loggedinsub {
		font-size: 16px;
		line-height: 25px;
	}

	.hero-btns {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.hero-btns .btn-hero {
		margin: 10px;
		height: 38px;
		width: 200px;
		font-size: 17px;
	}

	#loggedintitle {
		font-size: 30px;
		letter-spacing: 8px;
	}
}

@media screen and (max-width: 768px) {
	.left_hero {
		width: 0px;
		display: none;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.right_hero {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	.btn-mobile {
		display: block;
		text-decoration: none;
	}

	.hero-btns {
		margin-top: 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.hero-btns .btn-hero {
		font-size: 20px;
		margin: 10px;
		width: 200px;
		height: 45px;
	}

	#loggedintitle {
		font-size: 50px;
		letter-spacing: 8px;
	}

	#loggedinsub {
		font-size: 25px;
		margin-bottom: 35px;
	}

	#homePageSubTitle {
		font-size: 25px;
		margin-bottom: 35px;
	}

	#homePageTitle {
		font-size: 50px;
		letter-spacing: 8px;
	}
}
.loading {
  height: 100vh;
  overflow: block;
  width: 100%;
  background-color: #ebe6e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  text-align: center;
  color: #745140;
  font-size: 30px;
}
.loadinggif {
  width: 200px;
  height: 200px;
}
.loadingword {
  color: #b17b67;
  padding: 50px;
  letter-spacing: 3px;
}
.loadingbar {
  margin-top: 30px;
  width: 400px;
}

.footer-container {
  background-color: #c49483;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: wrap;
}
.footer-btn {
  padding: 8px 20px;
  font-size: 18px;
}

.footer-btn:hover {
  transition: all 0.3s ease-out;
  background: #c49483;
  color: #fff;
  border-color: #fff;
  transition: 250ms;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}
.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  width: 100%;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  color: #fff;
  background-color: #00000000;
  border: 1px solid #fff;
}

.btn-foot {
  text-decoration: none;
}
.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #fff;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #e1d7d2;
  text-align: center;
  align-items: center;
  font-weight: 100;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.footer-btn {
  font-family: 'Lora';
  background-color: #fff;
  color: #c49483;
  border: 1px solid #fff;
  letter-spacing: 0.5px;
}
.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    color: #fff;
    background-color: #00000000;
    border: 1px solid #fff;
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .btn-foot {
    width: 100%;
    text-decoration: none;
  }
}

@media screen and (max-width: 768px) {
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  font-family: 'Lora';
  outline: none;
  border: none;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.btn--primary {
  font-family: 'Lora';
  background-color: #745140;
  color: #e1d7d2;
  letter-spacing: 0.5px;
  border: 1px solid #745140;
}

.btn--outline {
  background-color: transparent;
  color: #e6dcd7;
  font-family: 'Lora';
  letter-spacing: 0.5px;
  padding: 8px 20px;
  border: 1px solid #e6dcd7;
  transition: all 0.3s ease-out;
}
.btn--footer {
  font-family: 'Lora';
  background-color: #fff;
  color: #c49483;
  border: 1px solid #fff;
  letter-spacing: 0.5px;
}
.btn--signup {
  background-color: transparent;
  letter-spacing: 0.5px;
  color: #fbf5ed;
  padding: 8px 20px;
  font-family: 'Lora';
  border: 1px solid #fbf5ed;
  transition: all 0.3s ease-out;
}
.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--footerSize {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large2 {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #e6dcd7;
  color: #b25634;
  transition: 250ms;
}

.btn--large2:hover {
  transition: all 0.3s ease-out;
  background: #00000000;
  color: #e1d7d2;
  border-color: #e1d7d2;
  transition: 250ms;
}

.btn--signupSize {
  transition: all 0.3s ease-out;
  background: #00000000;
  color: #fbf5ed;
  border-color: #fbf5ed;
  transition: 250ms;
}

.btn--footerSize:hover {
  transition: all 0.3s ease-out;
  background: #c49483;
  color: #fff;
  border-color: #fff;
  transition: 250ms;
}

.btn--signupSize:hover {
  background-color: #fbf5ed;
  color: #c49483;
  padding: 8px 20px;
  border: 1px solid #fbf5ed;
  transition: all 0.3s ease-out;
}

.navbar {
  background-color: #b25634;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  z-index: 100;
  width: 100%;
}
.red_notif {
  left: 16px;
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  color: #b25634;
  font-size: 10px;
  top: 25px;
  background-color: white;
}
.logwrap {
  width: wrap;
  left: 0;
}
.nav-buttonsign {
  display: flex;
  align-items: center;
}
.menu-bars {
  font-size: 2rem;
  background: none;
  margin-right: 30px;
  z-index: 10;
}
.icons_nav {
  color: #e6dcd7;
  height: 25px;
}
.icons_cross {
  height: 40px;
  padding-left: 10px;
  color: #e6dcd7;
}
.nav-menu {
  background-color: #e6dcd7;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 999;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  overflow-y: auto;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  /* transition: 350ms; */
}
.notif {
  background-color: #dbbfb1;
  width: 200px;
  display: none;
  height: wrap;
  justify-content: center;
  position: fixed;
  margin-top: 80px;
  margin-right: 0px;
  top: -100%;
  z-index: 100;
  right: 0;
}
.notif_link {
  text-decoration: none;
}
.outsidewrap {
  padding: 13px;
}
.notif_title {
  font-weight: 700;
  color: #745140;
  font-size: 18px;
  padding: 5px;
}
.notif_content {
  font-weight: 400;
  padding: 5px;
  color: #745140;
  font-size: 12px;
}
.notif.active {
  right: 0;
  top: 0;
  display: flex;
  z-index: 100;
  margin-top: 80px;
  margin-right: 0px;
  background-color: #dbbfb1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 0px 5px 6px;
  list-style: none;
  height: 60px;
}
.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #b17b67;
}

.nav-menu-items {
  width: 100%;
}
.navbar-toggle {
  background-color: #c49483;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.navwords {
  margin-left: 16px;
  font-size: 15px;
  color: #efe9e5;
}
.nav-m {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
}
@media screen and (max-width: 1080px) {
  #avatar {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-m {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    text-decoration: none;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-m.active {
    background: #e1d7d2;
    left: 0;
    justify-content: center;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #e6dcd7;
    color: #e1d7d2;
    text-decoration: none;
    border-radius: 0;
  }

  .navbar-logo {
    margin-left: 20px;
    top: 0;
    text-align: start;
    white-space: nowrap;
    position: relative;
    align-self: flex-start;
    transform: translate(20%, 50%);
  }

  .navbar-logo-notlogged {
    margin-left: 20px;
    position: relative;
    white-space: nowrap;
    top: 0;
    text-align: start;
    align-self: flex-start;
    transform: translate(20%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #e6dcd7;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #e6dcd7;
    padding: 14px 20px;
    border: 1px solid #e6dcd7;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #e6dcd7;
    color: #e1d7d2;
    text-decoration: none;
    transition: 250ms;
  }
}

/* here */

.navbar-container {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-grow: 1;
  margin-right: 30px;
  height: 80px;
  width: wrap;
}
path {
  stroke: #f5f5f5;
}
.navbar-container-logged {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 80px;
  width: wrap;
  flex-grow: 1;
}
.navbar-logo {
  color: #e6dcd7;
  margin-left: 20px;
  top: 0;
  text-align: start;
  white-space: nowrap;
  left: 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}
.wrap {
  cursor: pointer;
}
.wrap:hover {
  transition: 350ms;
  background-color: #e6dcd7;
}
.navbar-logo-notlogged {
  color: #e6dcd7;
  margin-left: 20px;
  top: 0;
  text-align: start;
  left: 0;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-item {
  height: 80px;
}
#avatar {
  height: 80px;
  color: #e6dcd7;
}
#avatar:hover {
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.nav-links {
  color: #e6dcd7;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #e6dcd7;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #e6dcd7;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  margin-right: 20px;
  display: none;
}

.menu-bell {
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: none;
  z-index: 10;
  color: #e6dcd7;
  stroke: #e6dcd7;
  cursor: pointer;
}

.icons_nav {
  color: #e6dcd7;
  stroke: #e6dcd7;
  border: #e6dcd7;
}

.about_uscont {
  padding-top: 60px;
  background-color: #efe9e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: wrap;
}
.about_ustitle {
  color: #745140;
  font-size: 50px;
}
.traitwrap {
  display: flex;
  padding: 50px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: end;
  padding-bottom: 0px;
}
.about_ustrait {
  padding-left: 40px;
  padding-right: 40px;
}
.traitename {
  color: #745140;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  float: bottom;
}

.traitcontent {
  color: #745140;
  text-align: justify;
  margin-top: 20px;
  font-size: 17px;
}
.about_usline {
  line-height: 5px;
  background-color: #c49483;
}

@media screen and (max-width: 1080px) {
  .traitename {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .about_ustrait {
    padding-left: 20px;
    padding-right: 20px;
  }
  .traitcontent {
    margin-top: 20px;
    font-size: 16px;
  }
}
@media screen and (max-width: 868px) {
  .traitwrap {
    display: flex;
    padding: 50px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: end;
    padding-bottom: 0px;
  }
  .traitename {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .about_ustrait {
    padding-left: 50px;
    padding-right: 50px;
  }
  .traitcontent {
    margin-top: 20px;
    margin-bottom: 100px;
    font-size: 16px;
  }
}

.team_pic {
	height: 250px;
	width: 250px;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.team_pic_colab {
	height: 200px;
	width: 200px;
}

.temp_pic {
	height: 250px;
	width: 250px;
	border-radius: 50%;
	background-color: whitesmoke;
}

.st_pic {
	height: 200px;
}

.team_name {
	text-align: center;
	padding-bottom: 20px;
	color: #745140;
	font-size: 30px;
}

.team_role {
	text-align: center;
	color: #745140;
	font-size: 20px;
	padding-bottom: 15px;
}

.team_line {
	background-color: #c49483;
}

.team_role_wrap {
	margin-top: 20px;
}

.about_uscont {
	width: 100%;
	padding-bottom: 200px;
}

.about_uscont {
	width: 100%;
}

.first {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	justify-content: space-evenly;
}
.current_team{
	display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
}
.teamwrap {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	grid-gap: 40px;
	gap: 40px;
}

.first_st {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	margin-top: 70px;
}

.stwrap {
	margin-top: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 70%;
}

.second_team {
	margin-top: 30px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.image_text_head,
.image_text_roles {
	color: white;
}

.image_text_head {
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 20px;
	letter-spacing: 2px;
}

/*Vertical Sliding*/
.wrapslidingwords {
	margin-top: 30px;
	display: inline;
	width: 450px;
}

.upcomingnewswrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 100px;
}

.upcoming {
	color: #c49483;
	font-size: 60px;
}

.newswords {
	color: #b25634;
	font-size: 120px;
}

.newswrap {
	width: 90%;
	display: flex;
	padding-left: 70px;
	height: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.slidingimage {
	display: flex;
	height: 500px;
	width: 500px;
	border-radius: 50%;
	background-color: whitesmoke;
}

.wrapslidingimage {
	display: inline;
	height: 500px;
	border-radius: 50%;
	width: 500px;
	overflow: hidden;
}

.slidingimage_img {
	height: 500px;
	border-radius: 50%;
	width: 500px;
}

.slidingwords {
	animation: topToBottom 20s linear infinite 0s;
	color: #745140;
	font-size: 20px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 500px;
}

.slidingimage {
	animation: image_top_to_bottom 20s linear infinite 0s;
	color: #745140;
	font-size: 20px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 500px;
}

.descriptionnews {
	color: #745140;
	font-size: 18px;
	margin-top: 40px;
}

#titlenews {
	font-size: 30px;
}

.slidingwords:nth-child(2) {
	animation-delay: 5s;
}

.slidingwords:nth-child(3) {
	animation-delay: 10s;
}

.slidingwords:nth-child(4) {
	animation-delay: 15s;
}

/* .slidingwords:nth-child(5) {
  animation-delay: 20s;
} */
.slidingimage:nth-child(2) {
	animation-delay: 5s;
}

.slidingimage:nth-child(3) {
	animation-delay: 10s;
}

#teamimage_bg {
	background-color: #e1d7d2;
}

.slidingimage:nth-child(4) {
	animation-delay: 15s;
}

/* .slidingimage:nth-child(5) {
  animation-delay: 20s;
} */

.slidingdescription:nth-child(2) {
	animation-delay: 5s;
}

.slidingdescription:nth-child(3) {
	animation-delay: 10s;
}

.slidingdescription:nth-child(4) {
	animation-delay: 15s;
}

/* .slidingdescription:nth-child(5) {
  animation-delay: 20s;
} */
/*topToBottom Animation*/
@keyframes topToBottom {
	0% {
		opacity: 0;
	}

	5% {
		opacity: 0;
		transform: translateY(-50px);
	}

	10% {
		opacity: 1;
		transform: translateY(0px);
	}

	25% {
		opacity: 1;
		transform: translateY(0px);
	}

	30% {
		opacity: 0;
		transform: translateY(50px);
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@keyframes image_top_to_bottom {
	0% {
		opacity: 0;
	}

	5% {
		opacity: 0;
		transform: translateY(-50px);
	}

	10% {
		opacity: 1;
		transform: translateY(0px);
	}

	25% {
		opacity: 1;
		transform: translateY(0px);
	}

	30% {
		opacity: 0;
		transform: translateY(50px);
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

.image_text_roles {
	font-size: 15px;
}

.image_text_team {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.image_team_overlay {
	position: absolute;
	cursor: pointer;
	background-color: rgba(196, 148, 131, 0.75);
	top: 0;
	left: 0;
	width: 250px;
	height: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	border-radius: 50%;
	transition: opacity 0.25s;
}

.image_team_overlay>* {
	transform: translateY(20px);
	transition: transform 0.25s;
}

.image_team_overlay:hover {
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);
	opacity: 1;
}

.image_team_overlay:hover>* {
	transform: translateY(0);
}

.person {
	position: relative;
}

.stwrapwrap {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 1220px) {
	.slidingimage {
		width: 320px;
		height: 320px;
	}

	.slidingimage_img {
		width: 320px;
		height: 320px;
	}

	.wrapslidingimage {
		width: 320px;
		height: 320px;
	}
}

@media screen and (max-width: 1220px) {
	.slidingwords {
		width: 400px;
	}
}

@media screen and (max-width: 1020px) {
	.slidingimage {
		width: 250px;
		height: 250px;
	}

	.slidingimage_img {
		width: 250px;
		height: 250px;
	}

	.wrapslidingimage {
		height: 250px;
		width: 250px;
	}

	.team_pic {
		height: 200px;
		width: 200px;
		border-radius: 50%;
	}

	.team_pic_colab {
		height: 150px;
		width: 150px;
	}

	.temp_pic {
		height: 200px;
		width: 200px;
	}

	.stwrap {
		flex-direction: column;
	}

	.first_st {
		width: 100%;
		margin-top: 50px;
		flex-direction: row;
		justify-content: space-around;
	}

	.team_name {
		text-align: center;
		padding-bottom: 20px;
		color: #745140;
		font-size: 25px;
	}

	.team_role {
		text-align: center;
		color: #745140;
		font-size: 17px;
		padding-bottom: 15px;
	}

	.team_line {
		background-color: #c49483;
	}

	.image_team_overlay {
		height: 200px;
		width: 200px;
	}

	.team_role_wrap {
		margin-top: 20px;
	}

	.current_team {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		width: 100%;
		justify-content: space-evenly;
	}

	.previous_team {
		margin-top: 30px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
}

@media screen and (max-width: 970px) {
	.slidingwords {
		width: 300px;
	}

	.slidingimage {
		display: none;
		width: 0;
		height: 0;
	}

	.slidingimage_img {
		display: none;
		width: 0;
		height: 0;
	}

	.wrapslidingimage {
		display: none;
		width: 0;
		height: 0;
	}

	.upcomingnewswrap {
		width: 100%;
		padding: 0;
		margin: 0;
		align-items: center;
		justify-content: center;
	}

	.newswrap {
		width: 100%;
		padding: 0;
		margin: 0;
		align-items: center;
		justify-content: center;
	}

	.wrapslidingwords {
		margin: 0;
		position: relative;
		margin-top: 30px;
		padding: 0;
		width: 500px;
		text-align: center;
	}

	.slidingwords {
		width: 500px;
	}
}

@media screen and (max-width: 760px) {
	.team_pic {
		margin-top: 30px;
		height: 300px;
		width: 300px;
		border-radius: 50%;
	}

	.team_pic_colab {
		height: 250px;
		width: 250px;
	}

	.temp_pic {
		margin-top: 30px;
		height: 300px;
		width: 300px;
	}

	.upcomingnewswrap {
		padding: 0;
	}

	.stwrapwrap {
		margin-top: 30px;
	}

	.image_team_overlay {
		margin-top: 30px;
		height: 300px;
		width: 300px;
	}

	.first_st {
		width: 100%;
		flex-direction: column;
		margin: 0px;
	}

	.image_text_head {
		margin-top: 15px;
		font-size: 30px;
	}

	.image_text_roles {
		font-size: 20px;
	}

	.team_name {
		text-align: center;
		padding-bottom: 20px;
		color: #745140;
		font-size: 30px;
	}

	.team_role {
		text-align: center;
		color: #745140;
		font-size: 20px;
		padding-bottom: 15px;
	}

	.team_line {
		background-color: #c49483;
	}

	.team_role_wrap {
		margin-top: 20px;
	}

	.first {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.second_team {
		width: 100%;
		display: flex;
		margin-top: 0px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
.title_function {
  color: #745140;
  font-weight: 100;
  font-size: 40px;
  margin-top: 20px;
  letter-spacing: 1px;
  margin-bottom: 30px;
}
.container_func {
  margin: 0;
  position: relative;
  background-color: #efe9e5;
  width: wrap;
  height: wrap;
  display: flex;
  overflow: block;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
  padding-bottom: 200px;
}
.temprow {
  display: flex;
  flex-direction: row;
}
.list_function {
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  text-decoration: none;
}
.outcont_function {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  padding: 40px;
}

path {
  stroke: #efe9e5;
}
.icon_func_even {
  height: 130px;
  width: 130px;
  color: #efe9e5;
  border-radius: 50%;
  background-color: #c49483;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  align-items: center;
}
.icon_func {
  height: 130px;
  width: 130px;
  padding: 30px;
  text-decoration: none;
}
.icon_func_odd {
  width: 130px;
  height: 130px;
  color: #efe9e5;
  border-radius: 50%;
  background-color: #b25634;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.subtitle_func {
  color: #745140;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
}
.func_link {
  text-decoration: none;
}
@media screen and (max-width: 1260px) {
  .list_function {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: center;
    align-items: center;
  }
  .icon_func_even {
    height: 100px;
    width: 100px;
  }
  .icon_func_odd {
    height: 100px;
    width: 100px;
  }
  .title_function {
    color: #745140;
    font-weight: 100;
    font-size: 27px;
    margin-bottom: 30px;
    margin-top: 20px;
    letter-spacing: 1px;
  }
  .subtitle_func {
    color: #745140;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
  }
}
.icon_func_even {
  transition: transform 0.3s;
}
.icon_func_odd {
  transition: transform 0.3s;
}
.icon_func_odd:hover {
  transform: scale(1.1);
}

.icon_func_even:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 768px) {
  .temprow {
    display: flex;
    flex-direction: column;
  }
  .icon_func_even {
    height: 140px;
    width: 140px;
  }
  .icon_func_odd {
    height: 140px;
    width: 140px;
  }
  .title_function {
    color: #745140;
    font-weight: 100;
    font-size: 20px;
    margin-bottom: 20px;
    /* margin-top: 700px; */
    letter-spacing: 1px;
  }
  .outcont_function {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    padding: 15px;
  }
  .subtitle_func {
    color: #745140;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
  }
}

.log {
  margin-top: 80px;
  display: flex;
  margin: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 100vh;
  z-index: 0;
  background-color: #e6dcd7;
}
.image_wrap_sign {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wordslog {
  align-items: flex-start;
  text-align: start;
  cursor: pointer;
  color: #745140;
}
.languagelog {
  margin: 0;
  top: 95px;
  right: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.signinglanguage {
  margin: 0;
  top: 95px;
  right: 15px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.pic_sign {
  padding: 30px;
  height: 70%;
}
.outerwrap_sign {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login_box {
  flex-direction: column;
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.title {
  color: #745140;
  font-size: 40px;
  font-weight: 400;
  margin-top: 130px;
  margin-bottom: 5px;
}
#signin_input {
  margin-top: 10px;
  padding: 10px 20px;
  height: 40px;
  padding-left: 30px;
  color: #b25634;
  width: 80%;
  font-weight: 600;
  letter-spacing: 0.8px;
  border: none;
  font-size: 20px;
  padding-top: 50px;
  padding-bottom: 20px;
  border-bottom: 1.5px solid rgb(178, 86, 52, 0.5);
}
#signin_input:focus {
  border: none;
  color: #b25634;
  outline: none;
  border-bottom: 1.5px solid rgb(178, 86, 52, 1);
}
#sigin_input_pass {
  padding: 10px 20px;
  padding-top: 50px;
  padding-left: 30px;
  width: 80%;
  padding-bottom: 20px;
  height: 40px;
  margin-top: 10px;
  font-size: 20px;
  color: #b25634;
  letter-spacing: 0.8px;
  font-weight: 600;
  border: none;
  border-bottom: 1.5px solid rgb(178, 86, 52, 0.5);
}
#sigin_input_pass:focus {
  border: none;
  outline: none;
  color: #b25634;
  border-bottom: 1.5px solid rgb(178, 86, 52, 1);
}
#signin_input::placeholder {
  color: #b25634;
  font-weight: 700;
  font-size: 20px;
  opacity: 0.5;
}
#sigin_input_pass::placeholder {
  color: #b25634;
  font-weight: 700;
  font-size: 20px;
  opacity: 0.5;
}
.formgroup {
  width: 450px;
  margin-left: 30px;
}
.formgroup i {
  position: relative;
  left: 0;
  top: 0px;
  width: 10px;
  font-size: 25px;
  padding-left: 15px;
  color: #b25634;
  opacity: 0.5;
}

.formgroup:focus-within #lock {
  opacity: 1;
  color: #b25634;
}
.formgroup:focus-within #user {
  opacity: 1;
  color: #b25634;
}
.formgroup:focus-within ::placeholder {
  opacity: 0.8;
  color: #b25634;
}

.btn-login {
  width: 170px;
  margin-top: 70px;
  border-radius: 3px;
  background-color: #b25634;
  padding: 8px 20px;
  font-size: 20px;
  color: #e6dcd7;
  border: 1px solid transparent;
  font-family: 'Lora';
}
.btn-loginpressed {
  width: 170px;
  margin-top: 70px;
  border-radius: 3px;
  background: #e6dcd7;
  padding: 8px 20px;
  font-size: 20px;
  color: #b25634;
  border: 1px solid #b25634;
  font-family: 'Lora';
  cursor: pointer;
}

.btn-login:hover {
  transition: all 0.3s ease-out;
  background: #e6dcd7;
  color: #b25634;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
.signUp {
  color: #b17b67;
  font-size: 15px;
  text-decoration: underline;
  margin-top: 30px;
  font-weight: 100;
}
.loginstat {
  color: #d92916;
  font-size: 15px;
  font-weight: 100;
  margin-bottom: 5px;
}

#showpass {
  font-weight: 100;
  font-size: 18px;
  opacity: 0.7;
  margin-left: 10px;
  color: #b25634;
}
#show {
  border: none;
}

#reset {
  border: none;
}
#clear {
  font-weight: 100;
  font-size: 15px;
  margin-left: 10px;
  opacity: 0.7;
  color: #b25634;
}
#clear:hover {
  opacity: 1;
  cursor: pointer;
}
#showpass:hover {
  cursor: pointer;
  opacity: 1;
}

@media screen and (max-width: 1260px) {
  .log {
    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }
  .image_wrap_sign {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pic_sign {
    height: 60%;
  }

  .login_box {
    flex-direction: column;
    display: flex;
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .image_wrap_sign {
    width: 0px;
    display: none;
  }
  .login_box {
    width: 100%;
    padding: 10;
  }
}

.all {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #e6dcd7;
  flex-direction: row;
  align-items: center;
}

.languagereg {
  position: absolute;
  padding: 20px;
  top: 0;
  text-decoration: none;
  color: #745140;
  right: 70px;
  cursor: pointer;
  display: flex;
  flex: row 1;
}
#registeredsucc {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 10px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

#return {
  background-color: whitesmoke;
  text-decoration: underline;
  color: #b25634;
  font-size: 13px;
  width: 100%;
  text-align: center;
  padding: 10px;
  padding-bottom: 20px;
}
.bar {
  width: 100px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #b25634;
}
.popup {
  display: none;
}
.dot {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin: 10px;
  border: 2px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000000;
  z-index: 300;
}
.innerdot {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  background-color: #ffffff;
}
.role {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.signintext {
  position: absolute;
  padding: 20px;
  top: 0;
  text-decoration: underline;
  color: #b25634;
  right: 0;
  cursor: pointer;
}
.regsub {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.reg_next_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 50px;
  align-items: flex-end;
  justify-content: right;
  margin-bottom: 50px;
}
.role_full {
  display: flex;
  background: #e6dcd7;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}
.full {
  display: flex;
  background: #e6dcd7;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0;
  width: 100%;
  justify-content: center;
  height: 100%;
}
.btn-role {
  font-family: 'Lora';
  width: 240px;
  height: 60px;
  margin-bottom: 0px;
  margin-right: 25px;
  margin-top: 25px;
  margin-left: 25px;
  cursor: pointer;
  background-color: #00000000;
  color: #b25634;
  letter-spacing: 0.5px;
  font-size: 25px;
  border: 1px solid #b25634;
  padding: 8px 20px;
}
.pic_reg {
  width: 25vw;
}
.regall {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-role:hover {
  background: #b25634;
  opacity: 0.5;
  color: rgba(239, 233, 229, 1);
}
.btn-role-selected {
  background: #b25634;
  color: #efe9e5;
  font-family: 'Lora';
  width: 240px;
  height: 60px;
  margin-bottom: 0px;
  margin-right: 25px;
  margin-top: 25px;
  margin-left: 25px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 25px;
  border: 1px solid #b25634;
  padding: 8px 20px;
}
.title_role {
  color: #745140;
  font-weight: 100;
  font-size: 30px;
}
.title_reg {
  color: #745140;
  font-weight: 100;
  font-size: 30px;
}
.warning_role {
  color: red;
  font-size: 17px;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 10px;
}
.regwords {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.warning {
  color: red;
  font-size: 17px;
  font-weight: 100;
  margin-top: 10px;
}
.emailwarning {
  color: red;
  font-size: 15px;
  font-weight: 100;
  margin-top: 10px;
  text-align: start;
}
.next {
  font-family: 'Lora';
  width: 150px;
  height: 45px;
  background-color: #00000000;
  color: #b25634;
  letter-spacing: 0.5px;
  padding: 10 20px;
  font-size: 20px;
  border: 1px solid #b25634;
  cursor: pointer;
}
.next:hover {
  transition: all 0.3s ease-out;
  background: #b25634;
  color: #efe9e5;
  border-color: #b25634;
  transition: 250ms;
}
.back {
  font-family: 'Lora';
  width: 150px;
  height: 45px;
  background-color: #00000000;
  color: #b25634;
  padding: 10 20px;
  letter-spacing: 0.5px;
  font-size: 20px;
  border: 1px solid #b25634;
  cursor: pointer;
}
.back:hover {
  transition: all 0.3s ease-out;
  background: #b25634;
  color: #efe9e5;
  border-color: #b25634;
  transition: 250ms;
}
.getstarted {
  margin-top: 100px;
}
.reg {
  margin-left: 0px;
}
.register_input::placeholder {
  opacity: 0.3;
  color: #b25634;
}
.reggroup:focus-within ::placeholder {
  color: #b25634;
  opacity: 0.5;
}
.btn_reg {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.register_selector {
  width: 70vw;
  color: #b25634;
  letter-spacing: 2px;
  font-weight: 400;
  margin: 10px;
  font-size: 20px;
  border: none;
}
.menu {
  letter-spacing: 2px;
}
.selectplace {
  color: rgba(178, 86, 52, 0.3);
  letter-spacing: 2px;
}
.select {
  border-color: rgba(178, 86, 52, 0.3);
}
.register_input {
  width: 70vw;
  padding: 10px 10px;
  color: #b25634;
  letter-spacing: 2px;
  font-weight: 400;
  margin: 10px;
  font-size: 20px;
  border: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.regleft {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 7vw;
}
.regright {
  padding-left: 7vw;
}

.register_input:focus {
  border: none;
  color: #b25634;
  outline: none;
  opacity: 0.9;
  border-bottom: 1.5px solid #b25634;
}
.basic_input {
  margin-top: 25px;
  width: 700px;
  color: #b25634;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 20px;
  border: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.basic_input:focus {
  border: none;
  color: #b25634;
  outline: none;
  opacity: 0.9;
  border-bottom: 1.5px solid #b25634;
}

.basic_input::placeholder {
  font-weight: 400;
}
#reset_reg {
  border: none;
  cursor: pointer;
  z-index: 20;
}

#clear_reg {
  font-size: 13px;
  opacity: 0.5;
  z-index: 20;
  color: #b25634;
  margin-left: -20px;
}
#showpass_reg {
  font-size: 17px;
  z-index: 20;
  color: #b25634;
  margin-left: -20px;
}
#show_reg {
  border: none;
  z-index: 20;
  opacity: 0.7;
  cursor: pointer;
}
#clear_reg:hover {
  opacity: 1;
}
#show_reg:hover {
  opacity: 1;
}
.birthdayreggroup {
  padding: 10px;
}
.birthdayreggroup:focus-within ::placeholder {
  color: #b25634;
  opacity: 0.5;
}
@media screen and (max-width: 780px) {
  .regleft {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .btn-role {
    padding: 8px 20px;
    margin-bottom: 0px;
  }
  .regright {
    display: none;
    width: 0;
  }
  .full {
    padding-left: 100px;
    padding-right: 100px;
  }
  .register_input {
    width: 80vw;
  }

  .register_selector {
    width: 80vw;
  }
  .bar {
    display: none;
  }
}

@media screen and (max-width: 1260px) {
  .btn-role {
    padding: 8px 20px;
    margin-bottom: 0px;
  }
}

#outcontainer {
  margin-top: 80px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
#container {
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}
#bookingprogramdia {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  font-weight: 700;
  color: #b25634;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
.book_button_wrap {
  width: 100%;
}
#favoritetitle {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  font-weight: 700;
  color: #b25634;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 15px;
  text-align: center;
}
.book_button_wrap {
  background-color: #f5f5f5;
}
.book_title {
  align-items: center;
  background-color: #f5f5f5;
  color: #b25634;
  justify-content: center;
  position: relative;
  text-align: center;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 20px;
}
.book_outside_wrap {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: wrap;
  width: 100%;
  padding: 30px;
  padding-top: 5px;
}
.book_button {
  align-items: center;
  background-color: #f5f5f5;
  color: #b25634;
  display: flex;
  flex-direction: row;
  border: 1px solid #b25634;
  height: wrap;
  justify-content: center;
  border-radius: 10px;
  overflow: block;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 15px;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: 150ms;
}
.book_button:hover {
  background-color: #b25634;
  color: #f5f5f5;
}
#bookingprogramdia_sub {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 14px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
#cardbox {
  background-color: #efe9e5;
  height: 100%;
  position: relative;
  padding-bottom: 10px;
}
#topic {
  font-family: 'Lora';
  color: #212324;
}
#subtopic {
  font-family: 'Lora';
  color: #4a7d76;
}

#add {
  position: absolute;
  font-family: 'Lora';
  color: #b25634;
  margin-bottom: 5px;
  bottom: 0px;
}
.heart {
  color: #b25634;
  margin-right: 3px;
}

#image {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.2s linear;
}
#wrap {
  padding: 30px;
  height: 300px;
  overflow: block;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}
#innerwrap {
  position: absolute;
  padding-right: 30px;
  bottom: 20px;
}
#dialog_topic,
#dialog_subtitle {
  color: #fdfaf1;
  font-family: 'Lora';
}

#gridcontainer,
#fav_gridcontainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 100px;
}
#dialog_topic {
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 10px;
}
#book {
  color: #fdfaf1;
  font-family: 'Lora';
  background-color: #60a399;
  padding-right: 7px;
  padding-left: 7px;
}

#buttonwrap {
  padding-top: 15px;
}
#dialog_add {
  margin-left: 15px;
  color: #fdfaf1;
  font-family: 'Lora';
  background-color: #eba68a;
  padding-right: 7px;
  padding-left: 7px;
}
.heart_dialog {
  color: #fdfaf1;
  margin-right: 3px;
}
#lowerwrap {
  padding: 30px;
  background-color: #e1d7d2;
}
#loc {
  display: flex;
  flex-direction: row;
}
.loc_dialog {
  padding: 0;
  color: #b25634;
  font-size: 210%;
}
#position {
  font-size: 15px;
  margin-left: 10px;
  color: #485861;
  font-weight: 600;
}
#subject {
  width: 30%;
  padding-left: 15px;
  font-weight: 500;
  padding-top: 3px;
  font-size: 15px;
  color: #4a7d76;
}
#content {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
}
#school_content {
  width: 70%;
  font-size: 12.5px;
  color: #625f5f;
  line-height: 23px;
}
#misc {
  font-size: 10px;
  width: 30%;
  padding-left: 15px;
}
.basicinfo {
  margin-top: 3px;
}
#date_topic {
  color: #b25634;
  font-size: 12px;
}
#coordinator_topic,
#target_topic {
  margin-top: 9px;
  color: #49968c;
  font-size: 12px;
}
#word {
  margin-top: 80px;
}
#location_inner {
  width: 70%;
  display: flex;
  flex-direction: row;

  align-items: center;
}

.titlefav {
  color: #b25634;
  font-weight: 100;
  font-size: 50px;
  padding-top: 30px;
  padding-left: 50px;
}
#bookingprogramdia {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  font-weight: 700;
  color: #b25634;
  flex-direction: row;
  align-items: center;
  height: wrap;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  justify-content: center;
}
#bookingdelete {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  font-weight: 700;
  color: #b25634;
  flex-direction: row;
  align-items: center;
  height: wrap;
  cursor: pointer;
  padding: 15px;
  text-align: center;
  justify-content: center;
  text-decoration: underline;
}

#bookingprogramdia_sub {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  flex-direction: row;
  font-weight: 400;
  text-align: center;
  align-items: center;
  height: wrap;
  font-size: 14px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  justify-content: center;
}

#fav_outcontainer {
  margin-top: 80px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#outcontainer {
  margin-top: 80px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#gridcontainer,
#fav_gridcontainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 100px;
}
#nofav {
  margin-top: 80px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
#fav_container {
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}

#fav_cardbox {
  background-color: #efe9e5;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}
#fav_topic {
  font-family: 'Lora';
  color: #b25634;
  text-align: center;
  font-weight: 100;
  font-size: 23px;
  margin-top: 15px;
}
#fav_subtopic {
  font-family: 'Lora';
  text-align: center;
  color: #c49483;
  font-size: 15px;
  margin-top: 15px;
}
#add {
  position: absolute;
  font-family: 'Lora';
  color: #b25634;
  margin-bottom: 5px;
  bottom: 0px;
}
.heart {
  color: #b25634;
  margin-right: 3px;
}
#fav_image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: auto;
  margin-top: 30px;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.2s linear;
}
#imagebox {
  overflow: hidden;
}

#imageouter {
  position: relative;
}
.image_overlay {
  position: absolute;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image_overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}
.image_overlay:hover {
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  opacity: 1;
}
.image_overlay:hover > * {
  transform: translateY(0);
}
.image_text {
  font-weight: bold;
  font-family: 'Lora';
  letter-spacing: 1px;
  color: #fdfaf1;
}
/* .onhover {
    position: absolute;
    visibility: visible;
    opacity: 1;
    top: 80;
    transition: opacity 0.2s, visibility 0.2s;
  } */
#wrap {
  padding: 30px;
  height: 300px;
  overflow: block;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}
#innerwrap {
  position: absolute;
  padding-right: 30px;
  bottom: 20px;
}
#dialog_topic,
#dialog_subtitle {
  color: #fdfaf1;
  font-family: 'Lora';
}

#dialog_topic {
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 10px;
}
#book {
  color: #fdfaf1;
  font-family: 'Lora';
  background-color: #60a399;
  padding-right: 7px;
  padding-left: 7px;
}

#buttonwrap {
  padding-top: 15px;
}
#dialog_add {
  margin-left: 15px;
  font-family: 'Lora';
  background-color: #eba68a;
  color: #b25634;
  padding-right: 7px;
  padding-left: 7px;
}
.heart_dialog {
  color: #fdfaf1;
  margin-right: 3px;
}
#lowerwrap {
  padding: 30px;
  background-color: #e1d7d2;
}
#loc {
  display: flex;
  flex-direction: row;
}
.loc_dialog {
  padding: 0;
  color: #b25634;
  font-size: 210%;
}
#position {
  font-size: 15px;
  margin-left: 10px;
  color: #485861;
  font-weight: 600;
}
#subject {
  width: 30%;
  padding-left: 15px;
  font-weight: 500;
  padding-top: 3px;
  font-size: 15px;
  color: #4a7d76;
}
#content {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
}
#school_content {
  width: 70%;
  font-size: 12.5px;
  color: #625f5f;
  line-height: 23px;
}
#misc {
  font-size: 10px;
  width: 30%;
  padding-left: 15px;
}
.basicinfo {
  margin-top: 3px;
}
#date_topic {
  color: #b25634;
  font-size: 12px;
}
#coordinator_topic,
#target_topic {
  margin-top: 9px;
  color: #49968c;
  font-size: 12px;
}
#word {
  margin-top: 80px;
}
#location_inner {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#viewschool {
  font-family: 'Lora';
  width: 200px;
  cursor: pointer;
  background-color: #c49483;
  color: #efe9e5;
  letter-spacing: 0.5px;
  font-size: 13px;
  border: 1px solid #c49483;
  border-radius: 5px;
  padding: 8px 20px;
}

.admin_wrap {
  width: 100%;
  height: wrap;
  padding: 50px;
  background: white;
}
.admin_title {
  text-align: center;
  color: black;
  font-size: 30px;
  margin-top: 30px;
}

.backtosignin {
  width: 100%;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
}
.subtitle {
  text-align: center;
  color: black;
  font-size: 20px;
  margin-top: 30px;
}

.content_admin {
  border: 1.5px solid lightgray;
  border-radius: 5px;
  padding: 5px;
}
.content_pair {
  cursor: pointer;
  border: 1.5px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  transition: 150ms;
}
.content_pair:hover {
  background-color: lightgray;
}
.admin_chart_pair {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  column-gap: 10px;
}
.chart_pair {
  margin-top: 30px;
}
.admin_notif {
  font-size: 15px;
  width: 100%;
  margin-top: 10px;
  text-align: left;
  color: gray;
}
.admin_dialog {
  border-radius: 10px;
}
.admin_dialog_text {
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  color: black;
  font-size: 20px;
}
.admin_dialog_wrap {
  padding: 20px;
}

.frame {
  background-color: #d8ccc7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}
.language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
#historywrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.none {
  display: none;
  height: 0px;
  width: 0px;
}
.expanded {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.expandedwrap {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  color: #b25634;
}
.margin {
  margin-left: 10px;
}
.margin1 {
  margin-left: 5px;
}
.hrs {
  color: #745140;
  text-align: left;
  font-size: 15px;

  display: flex;
  flex-direction: row;
}
.margin2 {
  margin-left: 3px;
}
.expand {
  color: #d6b7ab;
  cursor: pointer;
}
.choosekid.active {
  z-index: 999;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.multi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.borderstudent {
  width: 100%;
  text-align: center;
  color: whitesmoke;
}
.choosekid {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  top: 100px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}
.kidicon {
  left: 0;
  color: whitesmoke;
}

.lang {
  color: #745140;
  cursor: pointer;
}
.slash {
  color: #745140;
}
.background {
  align-self: center;
  background-color: #e6dcdc;
  width: 94%;
  flex-direction: column;
  height: wrap;
  display: flex;
  align-items: center;
  margin-top: 130px;
  margin-bottom: 30px;
}
.imageprofile {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  margin-top: -95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d6b7a7;
  z-index: 1;
}
.name {
  font-size: 25px;
  color: #745140;
  line-height: 50px;
}
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerprofile {
  height: 100%;
  width: 85%;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
}
.left {
  width: 50%;
  float: left;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.titleprofile {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contact {
  margin-bottom: 40px;
}
#bio_contact {
  margin-bottom: 0px;
}
.right {
  width: 50%;
  float: right;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}
.edit {
  height: 50px;
  font-size: 25px;
  color: #b25634;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: 0;
}
.titlepro {
  line-height: 50px;
  font-size: 21px;
  color: #745140;
}
.editn {
  font-size: 25px;
  color: #745140;
  text-align: center;
  border: none;
}
.warning_prof {
  margin-top: 10px;
  font-size: 13px;
  color: red;
}
.warning_prof_bio {
  margin-bottom: 5px;
  font-size: 13px;
  color: red;
}
.edity {
  padding: 0;
  margin-top: 1.5px;
  font-size: 25px;
  color: #745140;
  border: none;
  text-align: center;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.edity:focus,
.editn:focus {
  padding: 0;
  margin-top: 1.5px;
  font-size: 25px;
  color: #745140;
  text-align: center;
  outline: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.line {
  background-color: #c49483;
}
.information {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.edittable {
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
}
#phone_input::-webkit-outer-spin-button,
#phone_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#aboutmetitle {
  margin-top: 20px;
}
#bioandhobby {
  margin-top: 5px;
}
.texty {
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  border-radius: 5px;
  margin-left: 10px;
  padding: 10px;
  border: 1.5px solid rgba(178, 86, 52, 0.3);
}
.textn {
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  padding: 10px;
  font-size: 15px;
}
.texty::placeholder {
  color: #b25634;
  opacity: 0.7;
}
.textn::placeholder {
  color: #b25634;
  opacity: 0.7;
}
.texty:focus,
.textn:focus {
  padding: 0;
  color: #745140;
  padding: 7px 0px 7px;
  padding: 10px;
  line-height: 20px;
  font-size: 15px;
  text-align: start;
  outline: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.edittabley {
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  border: none;
  margin-left: 10px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.edittablen {
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  border: none;
  width: 270px;
}
.edittabley::placeholder {
  color: #b25634;
  opacity: 0.7;
}
.textbef {
  color: #745140;
  font-size: 15px;
  margin-right: 5px;
  width: wrap;
}
.wrapprof {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emailwarning_prof {
  color: red;
  font-size: 13px;
  font-weight: 100;
  margin-top: 20px;
}
.edittabley:focus,
.edittablen:focus {
  padding: 0;
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  text-align: start;
  outline: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.containpro {
  display: flex;
  flex-direction: row;
  height: 34px;
  justify-content: space-between;
}
textarea {
  resize: none;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: -webkit-max-content;
  min-height: max-content;
  overflow: auto;
  height: 150px;
  border: 1px #b25634;
}
.lock {
  color: #745140;
  height: 34px;
  font-size: 20px;
  cursor: pointer;
  right: 0;
}
.nameprof {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  height: 50px;
}
.pen {
  color: #745140;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-left: 20px;
}
.link {
  text-decoration: none;
}
.btn-prof {
  width: 170px;
  border-radius: 3px;
  border-color: transparent;
  letter-spacing: 1px;
  background-color: #d6b7ab;
  padding: 8px 20px;
  font-family: 'Lora';
  text-align: center;
  font-size: 17px;
  color: #fbf5ed;
  margin-bottom: 50px;
  text-decoration: none;
}
#prof_registeredsucc {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 17px;
  padding: 25px;
}
.btn-prof:hover {
  text-decoration: none;
  transition: all 0.3s ease-out;
  background: #745140;
  color: #e1d7d2;
  cursor: pointer;
  transition: 250ms;
}
.prof_icon_main {
  color: #745140;
  height: 70px;
  width: 70px;
}
@media screen and (max-width: 720px) {
  .containerprofile {
    display: flex;
    flex-direction: column;
  }
  .right {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  .left {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
}

.contactsubtitles {
  color: #b25634;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.googleMeetLinkTempchanging {
  background-color: rgba(245, 245, 245, 0.4);
  width: 90%;
  height: wrap;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}
.currentprogram {
  background-color: whitesmoke;
  width: 90%;
  height: wrap;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px;
}
.currentprogramcontent {
  color: #745140;
}
.currentprogramicon {
  color: #745140;
  margin-right: 20px;
}
.googlemeetwarning {
  color: #c49483;
  text-align: center;
  font-weight: 400;
}
.googlemeetfirst {
  color: #c49483;
  text-align: center;
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 18px;
}
.linkpersonal {
  color: #b25634;
  text-align: center;
  font-weight: 400;
  margin-bottom: 50px;
  font-size: 23px;
  margin-top: 15px;
}
.googlemeetedit {
  background-color: #d6b7ab;
  margin-top: 20px;
  padding: 10px;
  border-radius: 3px;
  color: #745140;
  line-height: 20px;
  font-size: 15px;
  border: none;
  outline: none;
  width: 80%;
}

.googlemeetedit::placeholder {
  color: #b25634;
  opacity: 0.7;
}
.googlemeetedit:focus {
  background-color: #d6b7ab;
  margin-top: 20px;
  padding: 10px;
  border-radius: 3px;
  color: #745140;
  line-height: 20px;
  font-size: 15px;
  border: none;
  width: 80%;
  outline: none;
}

.btn-save {
  margin-top: 30px;
  width: 170px;
  border-radius: 3px;
  border-color: transparent;
  letter-spacing: 1px;
  background-color: #745140;
  padding: 8px 20px;
  font-family: 'Lora';
  text-align: center;
  font-size: 17px;
  color: #fbf5ed;
  text-decoration: none;
}
.btn-save:hover {
  text-decoration: none;
  transition: all 0.3s ease-out;
  background: #e1d7d2;
  color: #745140;
  cursor: pointer;
  transition: 250ms;
}

.admin_wrap {
	width: auto;
	height: wrap;
	padding: 50px;
	background: #e1d7d2;
}

.admin_title {
	text-align: left;
	color: black;
	font-size: 30px;
	margin-top: 30px;
}

.backtosignin {
	width: 100%;
	text-align: left;
	text-decoration: underline;
	cursor: pointer;
	margin-bottom: 5px;
}

.content {
	border: 1.5px solid lightgray;
	border-radius: 5px;
	padding: 5px;
	overflow: auto;
}

.content_special {
	border: 1.5px solid lightgray;
	border-radius: 5px;
	padding: 5px;
	overflow: auto;
	color: darkgray;
}

.admin_chart_pu {
	margin-top: 40px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 10px;
	column-gap: 10px;
	grid-row-gap: 10px;
	row-gap: 10px;
}

.chart {
	margin-top: 30px;
}

.subtitle {
	text-align: center;
	color: black;
	font-size: 20px;
	margin-top: 30px;
}

.admin_input {
	width: 200px;
	padding: 10px 10px;
	color: gray;
	border: none;
	margin-top: 20px;
	border-bottom: 1.5px solid gray
}

.admin_input:focus {
	border: none;
	color: black;
	outline: none;
	opacity: 0.9;
	border-bottom: 1.5px solid black;
}

.admin_button {
	width: wrap;
	height: wrap;
	cursor: pointer;
	margin-left: 30px;
	background-color: #00000000;
	color: gray;
	border: 1px solid gray;
	border-radius: 5px;
	padding: 4px 8px;
}

.admin_button:hover {
	background: gray;
	color: white;
	transition: 250ms;
}

@media (max-width: 800px) {
	.admin_chart_pu {
		width: -webkit-max-content;
		width: max-content;
		grid-template-columns: repeat(6, 100px);
	}
}
.outsidecontainerapp {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
#edittodos {
  color: #745140;
  width: 95%;
  margin-top: 20px;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  border: 1.5px solid rgba(178, 86, 52, 0.3);
}
.submissionlinkwrap {
  display: flex;
  margin-top: 20px;
  width: 95%;
}
#editlink {
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  border-radius: 0px;
  padding: 10px;
  border: none;
  flex-grow: 1;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
#editlink:focus {
  outline: none;
  border: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
#editlink::placeholder {
  font-size: 15px;
  opacity: 0.5;
  color: #745140;
}
.choosekid.active {
  z-index: 999;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.multi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.borderstudent {
  width: 100%;
  text-align: center;
  color: whitesmoke;
}
.choosekid {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  top: 100px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}
.kidicon {
  left: 0;
  color: whitesmoke;
}

.nokid {
  display: flex;
  height: 500px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.googlemeet {
  padding-left: 30px;
  padding-right: 30px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wraptodos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noStudentFont {
  color: #745140;
  font-size: large;
}
.noStudentFont2 {
  color: #745140;
  margin-top: 30px;
  font-size: 25px;
  font-weight: 700;
}
#edittodos::placeholder {
  font-size: 15px;
  opacity: 0.5;
  color: #745140;
}
#edittodos:focus {
  outline: none;
  color: #745140;
  border: 1.5px solid rgba(178, 86, 52, 0.3);
}
.con {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.googlemeet,
.agenda,
.task,
.notes,
.classdate,
.classduration {
  width: 80vw;
  margin: 20px;
  background-color: #efe9e5;
  border-radius: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.icon_app {
  color: #745140;
  height: 30px;
  width: 30px;
}
.image_app {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#app_succ {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: #745140;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 20px;
  padding: 20px;
}
#app_registeredsucc {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: #745140;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 20px;
  padding: 10px;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
}
#appointment_sendtitle {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: darkred;
  flex-direction: row;
  font-weight: 700;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 17px;
  padding: 10px;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
}
.appointment_content {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  white-space: pre-line;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 14px;
  padding-top: 5px;
  padding-right: 20px;
  padding-left: 40px;
  text-align: left;
}
.appointment_subtitles {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: #745140;
  flex-direction: row;
  font-weight: 700;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 16px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 30px;
}

#app_send {
  border-radius: 3px;
  background-color: #efe9e5;
  padding: 8px 20px;
  width: wrap;
  text-align: center;
  font-size: 15px;
  color: #b25634;
  border: 1px solid #b25634;
  font-family: 'Lora';
  width: 100px;
}
.wrapappointment {
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  justify-content: end;
  align-items: center;
  padding-top: 50px;
}
#app_send:hover {
  transition: all 0.3s ease-out;
  background: #b25634;
  color: whitesmoke;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
#app_return {
  background-color: whitesmoke;
  font-weight: 700;
  color: #b25634;
  font-size: 15px;
  width: 100%;
  text-align: center;
  padding: 10px;
}
.agenda,
.task,
.notes,
.classdate,
.classduration {
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}
.image_app {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #d6b7a8;
}

.meet {
  border-radius: 3px;
  border-color: transparent;
  background-color: #d6b7ab;
  padding: 8px 30px;
  font-size: 20px;
  color: #fbf5ed;
  font-family: 'Lora';
}

.meet:hover {
  transition: all 0.3s ease-out;
  background: #745140;
  color: #e1d7d2;
  transition: 250ms;
  cursor: pointer;
}
.buttonwrapapp {
  margin-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.sendapp {
  border-radius: 3px;
  border-color: transparent;
  background-color: #d6b7ab;
  padding: 8px 30px;
  font-size: 20px;
  margin-bottom: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fbf5ed;
  font-family: 'Lora';
}

.sendapp:hover {
  transition: all 0.3s ease-out;
  background: #745140;
  color: #e1d7d2;
  transition: 250ms;
  cursor: pointer;
}
.namesection_app {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 30px;
  line-height: 30px;
}
.app_name {
  font-size: 20px;
  color: #b17b67;
}
.hours {
  font-size: 13px;
  color: #5a7184;
}
.app_title {
  color: #b25634;
  font-size: 25px;
  padding-bottom: 20px;
  padding-left: 20px;
}
.submissiontitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #745140;
}
.app_line {
  width: 80vw;
  background-color: #c49483;
  opacity: 0.5;
  height: 3px;
}
#editdate {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  color: #745140;
  height: 30px;
  font-weight: 400;
  letter-spacing: 0.8px;
  border: none;
  font-size: 15px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.appwrap {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.datedatewrap {
  width: 100%;
}
.editrest {
  color: #745140;
  height: 30px;
  font-weight: 400;
  width: 100%;
  letter-spacing: 0.8px;
  border: none;
  font-size: 15px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
#editdate:focus {
  border: none;
  color: #745140;
  outline: none;
  border-bottom: 1.5px solid #c49483;
}
#editdate::placeholder {
  font-size: 15px;
  opacity: 0.5;
  color: #745140;
}

.outcontainerprog {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 70px;
}
.outsidewrapsub {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choosekid.active {
  z-index: 999;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.multi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.borderstudent {
  width: 100%;
  text-align: center;
  color: whitesmoke;
}
.choosekid {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  top: 100px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}
.kidicon {
  left: 0;
  color: whitesmoke;
}

.prog_avatar {
  color: #fdfaf1;
  font-size: 30px;
}
.searchprog {
  height: 50px;
  background: #b17b67;
  text-align: left;
  width: 91%;
  padding-left: 20px;
  border-radius: 5px;
  line-height: 50px;
  font-size: 16px;
  margin: 0;
  color: #efe9e5;
}
.row1 {
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: left;
}
.title_rec {
  color: #fdfaf1;
  width: 30%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: #d69d88;
  border-right: 1.5px solid #d6b7a8;
  border-bottom: 1.5px solid #d6b7a8;
}
.title_not {
  color: #fdfaf1;
  width: 70%;
  padding-top: 20px;
  border-bottom: 1.5px solid #d6b7a8;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: #d69d88;
}
.row_rest {
  display: flex;
  flex-direction: row;
  align-items: left;
}
.row_none {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #745140;
  justify-content: center;
  background-color: #efe9e5;
  padding: 20px;
}
.selectorwrap_pu {
  width: 90%;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.selectplaceholder {
  color: #b17b67;
  font-size: 13px;
  letter-spacing: normal;
}
.helpertitle {
  color: #745140;
  font-size: 15px;
  margin-right: 10px;
}
.content_not {
  color: #fdfaf1;
  border-top: 1.5px solid #d6b7a8;
  width: 70%;
  color: #745140;
  padding: 20px;
  white-space: pre-line;
  font-size: 15px;
  background-color: #efe9e5;
  word-wrap: break-word;
}
.title_content_rec {
  color: #b25634;
  font-size: 17px;
  margin-top: 10pxs;
}
.content_content_rec {
  color: #745140;
  font-size: 12px;
  white-space: pre-line;
  margin-top: 5px;
}
.content_rec_sec {
  padding-bottom: 10px;
}
.content_rec {
  color: #fdfaf1;
  border-top: 1.5px solid #d6b7a8;
  width: 30%;
  border-right: 1.5px solid #d6b7a8;
  background-color: #efe9e5;
  padding: 20px;
}
.searchicon {
  font-size: 150%;
  margin-right: 8px;
  height: 50px;
  float: right;
}
.subject {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  padding-right: 30px;
  flex-direction: row;
  align-items: center;
  width: 90vw;
  justify-content: space-between;
  height: 120px;
  background-color: rgb(239, 233, 229, 0.8);
}
.second {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.imageprog {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #d6b7a8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sub {
  font-size: 27px;
  color: #745140;
}
.teacher {
  font-size: 13px;
  margin-top: 10px;
  color: #b17b67;
}
.time {
  margin-top: 5px;
  font-size: 13px;
  color: #5a7184;
}
.total {
  margin-left: 40px;
}
/* progressbar */
.progress {
  background-color: #d69d88;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
  display: flex;
  align-items: center;
  height: 35px;
  width: 50vw;
  padding-right: 20px;
}
.progressbar {
  margin-left: 50px;
  float: end;
}
.progress-done {
  background: #f1d7cb;
  border-radius: 7px;
  color: #b25634;
  margin-left: 10px;
  height: 60%;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  opacity: 0;
  padding-right: 20px;
  transition: 1s ease 0.3s;
}

.subjectlist {
  margin: 0;
  display: flex;
  padding: 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gridwrapprog {
  width: 100%;
  align-self: center;
  margin-right: 0px;
  margin-left: 0px;
}
.outestwrapprogram {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rowwrap {
  margin: 0;
  display: flex;
  padding: 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapwrap {
  margin: 0;
  display: flex;
  padding: 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.programusagewr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 820px) {
  .progressbar {
    width: 0;
    display: none;
  }
  .row1 {
    width: 90%;
    margin-right: 0px;
    margin-left: 0px;
  }
  .subjectlist {
    margin: 0;
    display: flex;
    padding: 0px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .subject {
    width: 90%;
    margin-top: 40px;
    align-self: center;
    margin-right: 0px;
    margin-left: 0px;
  }
  .wrapsubj {
    margin: 0;
    display: flex;
    padding: 0px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content_content_rec {
    color: #745140;
    font-size: 10px;
    margin-top: 5px;
  }
  .title_content_rec {
    color: #b25634;
    font-size: 15px;
  }
  .gridwrapprog {
    width: 90%;
    align-self: center;
    margin-right: 0px;
    margin-left: 0px;
  }
  .outsidewrapsub {
    width: 100%;
  }
  /* .content_not; */
}

.outerbook {
  margin: 150px;
  margin-right: 60px;
  height: wrap;
  margin-left: 60px;
  padding-bottom: 20px;
  background-color: #efe9e5;
  margin-bottom: 50px;
}
.choosekid.active {
  z-index: 999;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.multi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.borderstudent {
  width: 100%;
  text-align: center;
  color: whitesmoke;
}
.choosekid {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  top: 100px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}

.choosekidsmsg {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  bottom: 70px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}
.choosekidsmsg.active {
  z-index: 999;
  bottom: 70px;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.kidicon {
  left: 0;
  color: whitesmoke;
}

.outerbook_upcoming {
  margin-right: 60px;
  margin-top: 70px;
  height: wrap;
  margin-left: 60px;
  padding-bottom: 20px;
  background-color: #efe9e5;
  margin-bottom: 100px;
}
.outestcontainerbook {
  margin-top: 80px;
  margin-bottom: 50px;
  height: wrap;
}
.topbarbook {
  display: flex;
  flex-direction: row;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
}

.bookbtn {
  width: 170px;
  border-radius: 3px;
  text-align: center;
  background-color: #b25634;
  padding: 8px 20px;
  font-size: 18px;
  color: #efe9e5;
  border: 1px solid transparent;
  font-family: 'Lora';
}

.bookbtn:hover {
  transition: all 0.3s ease-out;
  background: #efe9e5;
  color: #b25634;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
.titlebook {
  color: #b25634;
  font-size: 30px;
}
.inputbook_outercont {
  display: flex;
  flex-direction: row;
  justify-content: left;
  /* flex: 1 1; */
  align-items: center;
  padding: 25px;
}
.titlebooksub {
  color: #745140;
  font-size: 22px;
  margin-right: 10px;
}
.enterwrap {
  flex-grow: 1;
}
.inputbooking {
  font-size: 20px;
  margin-left: 10px;
  width: 100%;
  color: #745140;
  text-align: left;
  border: none;
  padding: 10px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.inputstudentname {
  font-size: 20px;
  width: 100%;
  color: #745140;
  text-align: left;
  border: none;
  padding: 10px;
  padding-bottom: 0px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.inputstudentname:focus {
  font-size: 20px;
  color: #745140;
  text-align: left;
  outline: none;
  border-bottom: 1.5px solid rgba(116, 81, 64, 0.5);
}
.inputbook:focus {
  font-size: 20px;
  color: #745140;
  text-align: left;
  outline: none;
  border-bottom: 1.5px solid rgba(116, 81, 64, 0.5);
}
.css-1wt0ykv::after {
  border-bottom: 1.5px solid rgba(116, 81, 64, 0.5);
}
.inputbook::placeholder {
  font-size: 20px;
  color: #b17b67;
  text-align: left;
}
.bookingprogramdia {
  padding: 30px;
  background-color: whitesmoke;
  color: #745140;
  text-align: center;
  font-size: 20px;
}
#largerpadding {
  padding-left: 60px;
  padding-right: 60px;
}
.bookingprogramdia_sub {
  padding: 15px;
  color: #b17b67;
  background-color: whitesmoke;
  padding-left: 30px;
}
.sendbookingbtn {
  width: wrap;
  border-radius: 3px;
  background-color: #b25634;
  padding: 8px 20px;
  font-size: 13px;
  color: #efe9e5;
  border: 1px solid transparent;
  font-family: 'Lora';
}
.sendbookwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  padding: 20px;
}
.sendbookingbtn:hover {
  transition: all 0.3s ease-out;
  background: whitesmoke;
  color: #b25634;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
.bookingfinal {
  padding: 20px;
  background-color: whitesmoke;
  color: #745140;
  font-weight: 700;
}

.bookingoutestwrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.bookingwrappbottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.bookingtitleall {
  font-size: 35px;
  color: #745140;
  width: 100%;
  margin-bottom: 20px;
}
.bookingtitlebooking {
  font-size: 35px;
  color: #745140;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
}
.bookingrow {
  background-color: #efe9e5;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 15px;
  box-shadow: 12px 12px 4px -8px rgb(197, 192, 192);
  justify-content: start;
}
.bookingprog_avatar {
  color: #fdfaf1;
  font-size: 30px;
}
.bookingbuttonswrapping {
  display: flex;
  flex-direction: row;
}
.bookingwordswrapfirst {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
}
.bookingwrapsecond {
  padding: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bookingimageprog {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #d6b7a8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bookingrequestsub {
  font-size: 25px;
  color: #745140;
}
.bookingrequesttime {
  font-size: 23px;
  color: #745140;
}
.bookingrequestdetailtime {
  margin-top: 5px;
  font-size: 27px;
  color: #5a7184;
}
.detailtimeforupcomings {
  font-size: 27px;
  color: #b25634;
}

.detailtimeerrornobook {
  font-size: 23px;
  color: #745140;
}

.detailtimeforbook {
  font-size: 27px;
  color: #5a7184;
}
.bookinrequesttime {
  margin-top: 5px;
  font-size: 17px;
  color: #5a7184;
}
.bookingrequesttotal {
  height: 100%;
  margin-left: 30px;
}
.bookingcontent {
  color: #745140;
  font-size: 30px;
}
.bookingrequesttotaltime {
  height: 100%;
  margin-left: 50px;
}

.bookingtime {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}
.bookingwraping {
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: wrap;
  margin-top: 80px;
}
.lineforbooking {
  background-color: #d8ccc7;
  color: #d8ccc7;
  margin-bottom: 50px;
  margin-top: 50px;
  line-height: 2px;
}
.bookingwrappinginnerfirst {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.buttonbookingcheck {
  border-radius: 3px;
  background-color: #b17b67;
  padding: 8px 20px;
  font-size: 20px;
  color: #ffffff;
  border: 1px solid transparent;
  font-family: 'Lora';
  margin-left: 10px;
}

.buttonbookingcheck:hover {
  transition: all 0.3s ease-out;
  background: #efe9e5;
  color: #b17b67;
  transition: 250ms;
  border: 1px solid #b17b67;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .bookingrequesttotal {
    display: none;
  }
}
@media screen and (max-width: 930px) {
  .bookingrequesttotaltime {
    display: none;
  }
}

.bookingconfirmheaders {
  padding: 30px;
  background-color: whitesmoke;
  color: #b25634;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}

.bookingcancelleddiatitle {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
  background-color: whitesmoke;
  color: #b25634;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}
.navigateheaderdia {
  padding-right: 30px;
  cursor: pointer;
  padding-bottom: 30px;
  padding-top: 13px;
  padding-left: 30px;
  background-color: whitesmoke;
  color: #745140;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}

.bookingconfirmcontent {
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: whitesmoke;
  color: #b17b67;
  font-size: 17px;
  text-align: center;
}

.bookingrow_teacher {
  background-color: #efe9e5;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 15px;
  justify-content: start;
}

.outerbook {
  margin: 150px;
  margin-right: 60px;
  height: wrap;
  margin-left: 60px;
  padding-bottom: 20px;
  background-color: #efe9e5;
  margin-bottom: 50px;
}
.choosekid.active {
  z-index: 999;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.outwrappingbook {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}
.multi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.borderstudent {
  width: 100%;
  text-align: center;
  color: whitesmoke;
}
.choosekid {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  top: 100px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}

.choosekidsmsg {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  bottom: 70px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}
.choosekidsmsg.active {
  z-index: 999;
  bottom: 70px;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.kidicon {
  left: 0;
  color: whitesmoke;
}

.outerbook_upcoming {
  margin-right: 60px;
  margin-top: 70px;
  height: wrap;
  margin-left: 60px;
  padding-bottom: 20px;
  background-color: #efe9e5;
  margin-bottom: 100px;
}
.outestcontainerbook {
  margin-top: 80px;
  margin-bottom: 50px;
  height: wrap;
}
.topbarbook {
  display: flex;
  flex-direction: row;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
}

.bookbtn {
  width: 170px;
  border-radius: 3px;
  text-align: center;
  background-color: #b25634;
  padding: 8px 20px;
  font-size: 18px;
  color: #efe9e5;
  border: 1px solid transparent;
  font-family: 'Lora';
}

.bookbtn:hover {
  transition: all 0.3s ease-out;
  background: #efe9e5;
  color: #b25634;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
.titlebook {
  color: #b25634;
  font-size: 30px;
}
.inputbook_outercont {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 25px;
}
.titlebooksub {
  color: #745140;
  font-size: 22px;
}
.inputbook {
  font-size: 20px;
  margin-left: 10px;
  width: 90%;
  color: #745140;
  text-align: left;
  border: none;
  padding: 10px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.inputbook:focus {
  font-size: 20px;
  color: #745140;
  text-align: left;
  outline: none;
  border-bottom: 1.5px solid rgba(116, 81, 64, 0.5);
}
.inputbook::placeholder {
  font-size: 20px;
  color: #b17b67;
  text-align: left;
}
.bookingprogramdia {
  padding: 20px;
  background-color: whitesmoke;
  color: #745140;
}
.bookingprogramdia_sub {
  padding: 15px;
  color: #b17b67;
  background-color: whitesmoke;
  padding-left: 30px;
}
.sendbookingbtn {
  width: wrap;
  border-radius: 3px;
  background-color: #b25634;
  padding: 8px 20px;
  font-size: 13px;
  color: #efe9e5;
  border: 1px solid transparent;
  font-family: 'Lora';
}
.sendbookwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  padding: 20px;
}
.sendbookingbtn:hover {
  transition: all 0.3s ease-out;
  background: whitesmoke;
  color: #b25634;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
.bookingfinal {
  padding: 20px;
  background-color: whitesmoke;
  color: #745140;
  font-weight: 700;
}

.bookingoutestwrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.bookingwrappbottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.bookingtitleall {
  font-size: 35px;
  color: #745140;
  width: 100%;
  margin-bottom: 20px;
}
.bookingtitlebooking {
  font-size: 35px;
  color: #745140;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
}
.bookingrow {
  background-color: #efe9e5;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 15px;
  box-shadow: 12px 12px 4px -8px rgb(197, 192, 192);
  justify-content: start;
}
.bookingprog_avatar {
  color: #fdfaf1;
  font-size: 30px;
}
.bookingbuttonswrapping {
  display: flex;
  flex-direction: row;
}
.bookingwordswrapfirst {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
}
.bookingwrapsecond {
  padding: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bookingimageprog {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #d6b7a8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bookingrequestsub {
  font-size: 25px;
  color: #745140;
}
.bookingrequesttime {
  font-size: 23px;
  color: #745140;
}
.bookingrequestdetailtime {
  margin-top: 5px;
  font-size: 27px;
  color: #5a7184;
}
.detailtimeforupcomings {
  font-size: 27px;
  color: #b25634;
}

.detailtimeerrornobook {
  font-size: 23px;
  color: #745140;
}

.detailtimeforbook {
  font-size: 27px;
  color: #5a7184;
}
.bookinrequesttime {
  margin-top: 5px;
  font-size: 17px;
  color: #5a7184;
}
.bookingrequesttotal {
  height: 100%;
  margin-left: 30px;
}
.bookingcontent {
  color: #745140;
  font-size: 30px;
}
.bookingrequesttotaltime {
  height: 100%;
  margin-left: 50px;
}

.bookingtime {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}
.bookingwraping {
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: wrap;
  margin-top: 80px;
}
.lineforbooking {
  background-color: #d8ccc7;
  color: #d8ccc7;
  margin-bottom: 50px;
  margin-top: 50px;
  line-height: 2px;
}
.bookingwrappinginnerfirst {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.buttonbookingcheck {
  border-radius: 3px;
  background-color: #b17b67;
  padding: 8px 20px;
  font-size: 20px;
  color: #ffffff;
  border: 1px solid transparent;
  font-family: 'Lora';
  margin-left: 10px;
}

.buttonbookingcheck:hover {
  transition: all 0.3s ease-out;
  background: #efe9e5;
  color: #b17b67;
  transition: 250ms;
  border: 1px solid #b17b67;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .bookingrequesttotal {
    display: none;
  }
}
@media screen and (max-width: 930px) {
  .bookingrequesttotaltime {
    display: none;
  }
}

.bookingconfirmheaders {
  padding: 30px;
  background-color: whitesmoke;
  color: #745140;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.bookingconfirmcontent {
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: whitesmoke;
  color: #b17b67;
  font-size: 17px;
  text-align: center;
}

.bookingrow_teacher {
  background-color: #efe9e5;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 15px;
  justify-content: start;
}

.navbar_port {
  background-color: #c49483;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  align-self: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}
.nav-buttonsign {
  display: flex;
  align-items: center;
}
.menu-bars {
  font-size: 2rem;
  background: none;
  z-index: 10;
  margin-right: 30px;
}
.icons_nav {
  color: #e6dcd7;
  height: 25px;
}
.icons_cross {
  height: 40px;
  padding-left: 10px;
  color: #e6dcd7;
}
.nav-menu {
  background-color: #e6dcd7;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 999;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  overflow-y: auto;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 0px 5px 6px;
  list-style: none;
  height: 60px;
}
.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #b17b67;
}

.nav-menu-items {
  width: 100%;
}
.navbar-toggle {
  background-color: #c49483;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.navwords {
  margin-left: 16px;
  font-size: 15px;
  color: #efe9e5;
}
.nav-m {
  display: grid;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
}
@media screen and (max-width: 1080px) {
  #avatar {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-m {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    text-decoration: none;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-m.active {
    background: #e1d7d2;
    left: 0;
    justify-content: center;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #e6dcd7;
    color: #e1d7d2;
    text-decoration: none;
    border-radius: 0;
  }

  .navbar-logo {
    position: relative;
    margin-left: 0;
    white-space: nowrap;
    top: 0;
    text-align: start;
    align-self: flex-start;
    transform: translate(20%, 50%);
  }

  .navbar-logo-notlogged {
    position: relative;
    margin-left: 0;
    white-space: nowrap;
    top: 0;
    text-align: start;
    align-self: flex-start;
    transform: translate(20%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #e6dcd7;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #e6dcd7;
    padding: 14px 20px;
    border: 1px solid #e6dcd7;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #e6dcd7;
    color: #e1d7d2;
    text-decoration: none;
    transition: 250ms;
  }
}

/* here */

.navbar-container {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 80px;
  width: wrap;
  flex-grow: 1;
  margin-right: 30px;
}
path {
  stroke: #f5f5f5;
}
.navbar-container-logged {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 80px;
  width: wrap;
  flex-grow: 1;
}
.navbar-logo {
  color: #e6dcd7;
  margin-left: 20px;
  position: relative;
  white-space: nowrap;
  top: 0;
  text-align: start;
  left: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}
.navbar-logo-notlogged {
  color: #e6dcd7;
  position: relative;
  margin-left: 10px;
  top: 0;
  white-space: nowrap;
  text-align: start;
  left: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-item {
  height: 80px;
}
#avatar {
  height: 80px;
  color: #e6dcd7;
}
#avatar:hover {
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.nav-links {
  color: #e6dcd7;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #e6dcd7;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #e6dcd7;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  margin-right: 20px;
  display: none;
}

.outcontainer_port {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: #efe9e5;
}
.image_port_cont {
  width: 150px;
  display: flex;
  height: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f1ef;
  border-radius: 10px;
  box-shadow: 15px 15px 5px -8px #e6dcd7;
}

.nokidport {
  display: flex;
  height: 200px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.stuprof_icon {
  width: 60px;
  height: 60px;
  color: #c49483;
}

.top_bar {
  display: flex;
  flex-direction: row;
  height: wrap;
  background-color: #c49483;
}
.pic_port {
  width: 500px;
  padding: 30px;
}
.t_port {
  font-size: 40px;
  margin-bottom: 10px;
  color: #efe9e5;
}
.sub_port {
  font-size: 20px;
  color: #745140;
  margin-bottom: 20px;
}
.image_port {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.words_port {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subline {
  margin-top: 30px;
  background-color: #efe9e5;
  line-height: 0.5px;
}
.button_port {
  margin: 5px;
  width: 190px;
  border-radius: 3px;
  background-color: #00000000;
  padding: 8px 20px;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
  color: #efe9e5;
  border: 1px solid #efe9e5;
  font-family: 'Lora';
}

.button_port:hover {
  transition: all 0.3s ease-out;
  background: #efe9e5;
  color: #c49483;
  transition: 250ms;
  border: 1px solid #c49483;
  cursor: pointer;
}

.basics {
  margin: 50px;
  height: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.colorfilling {
  width: 10%;
  background-color: #f6f1ef;
  margin-left: -30px;
}
.studentimg {
  width: 150px;
  height: 150px;
  z-index: 1;
  border-radius: 50%;
}
.basicinfo_student {
  width: 100%;
  box-shadow: 15px 15px 5px -8px #e6dcd7;
  display: flex;
  border-radius: 5px;
  padding: 20px;
  margin-left: 30px;
  padding-left: 50px;
  flex-direction: column;
  justify-content: center;
  background-color: #f6f1ef;
}
.name_port {
  color: #b17b67;
  font-size: 22px;
  padding: 5px;
}
.others_port {
  padding: 5px;
  color: #745140;
  font-size: 17px;
}
.teachername {
  color: #745140;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  margin-top: 30px;
  font-weight: 700;
}
.grid_port {
  display: flex;
  flex-direction: row;
  margin: 30px;
  margin-bottom: 50px;
  /* width: 100%; */
}
.grid_port_student {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  padding: 30px;
  width: 90%;
}
.first_port {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.second_port {
  width: 50%;
}
.infobox {
  padding-bottom: 30px;
  padding-top: 30px;
  margin: 20px;
  background-color: #f6f1ef;
  box-shadow: 12px 12px 5px -8px #e6dcd7;
}
.title_port {
  color: #b17b67;
  font-size: 24px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
.primline {
  background-color: #e1d7d2;
  margin-bottom: 20px;
}
.learn_words {
  color: #745140;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  font-size: 17px;
}
.content_port_abt {
  color: #745140;
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.content_port {
  color: #745140;
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 15px;
}
.subtitle_port {
  color: #b17b67;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  margin-top: 30px;
}
.subtitle_sub_port {
  padding-left: 30px;
  padding-right: 30px;
  color: #c49483;
  margin-top: 30px;
  font-size: 17px;
}
.learn_more {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  width: auto;
  border-radius: 3px;
  background-color: #00000000;
  padding: 8px 20px;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  max-width: 300px;
  color: #b17b67;
  border: 1px solid #b17b67;
  font-family: 'Lora';
}
.learn_more_second {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  width: auto;
  border-radius: 3px;
  background-color: #00000000;
  padding: 8px 20px;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  max-width: 300px;
  color: #b17b67;
  border: 1px solid #b17b67;
  font-family: 'Lora';
}
.learn_more_second:hover {
  transition: all 0.3s ease-out;
  background: #efe9e5;
  color: #c49483;
  text-decoration: none;
  transition: 250ms;
  border: 1px solid #c49483;
  cursor: pointer;
}
.studymat {
  text-decoration: none;
}
.learn_more:hover {
  transition: all 0.3s ease-out;
  background: #efe9e5;
  color: #c49483;
  text-decoration: none;
  transition: 250ms;
  border: 1px solid #c49483;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .grid_port {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
  }

  .grid_port_student {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
  }
  .infobox {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .image_port_cont {
    width: 0;
    display: none;
  }
  .stuprof_icon {
    width: 0;
    display: none;
  }
  .first_port {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .second_port {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  .image_port {
    width: 0;
    display: none;
  }
  .words_port {
    margin-top: 80px;
    width: 100%;
    margin-bottom: 30px;
  }
  .image_port_cont {
    width: 0;
    display: none;
  }
  .basicinfo_student {
    width: 90%;
    margin: 0;
  }
  .basics {
    margin: 0;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .basicinfo_student {
    margin-left: 0px;
    padding-left: 25px;
  }
}

.frameteacherport {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.backgroundteacher {
  background-color: #efe9e5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.out {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 80px;
  height: 100%;
}
.unsentmsg {
  border-radius: 10px;
  color: whitesmoke;
  padding: 5px;
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
  padding-left: 15px;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  padding-right: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}
.warningmsg {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #745140;
  font-weight: 700;
  font-size: 25px;
  padding: 20px;
}
.chathistory {
  background-color: #e1d7d2;
  width: 30%;
  display: flex;
  flex-direction: column;
}
.searchpad {
  padding: 20px;
}
.search {
  height: 50px;
  background: #b17b67;
  text-align: left;
  padding-left: 20px;
  border-radius: 5px;
  line-height: 50px;
  font-size: 16px;
  margin: 0;
  color: #efe9e5;
}
.searchicon {
  font-size: 150%;
  margin-right: 8px;
  height: 50px;
  float: right;
}

.peoplelist {
  height: calc(100vh-50px);
}
.chatcontent {
  background-color: #d8ccc7;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatname {
  width: 70%;
  height: 60px;
  text-align: left;
  padding-left: 20px;
  line-height: 60px;
  right: 0;
  color: #745140;
  position: absolute;
  font-size: 18px;
  background-color: rgb(225, 215, 210, 0.5);
}
.chatwraps {
  height: calc(100vh - 260px);
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 120px;
}
.chat {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 260px);
  width: 100%;
  padding: 15px;
  overflow-y: auto;
}

.send {
  height: 120px;
  width: 70%;
  position: absolute;
  bottom: 0;
  padding-top: 15px;
  color: #b17b67;
  font-size: 15px;
  right: 0;
  padding-left: 20px;
  background-color: rgb(225, 215, 210, 0.5);
}
.outerbox {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
}
.shadowing {
  padding-bottom: 25px;
  padding-top: 25px;
}
.shadowing:hover {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.05);
}
.imagemsg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #d6b7a8;
}
.msg_icon {
  font-size: 20px;
  color: #fdfaf1;
}
.infoboxmsg {
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  align-items: left;
}

.namemsg {
  font-size: 15px;
  color: #745140;
}
.latestmsg {
  margin-top: 10px;
  font-size: 13px;
  color: #c49483;
}
.numbermsg {
  background-color: #b17b67;
  height: 20px;
  align-self: right;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  color: #d8ccc7;
}
.align {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}

.messagesend {
  color: #745140;
  height: 100%;
  padding: 7px 0px 7px;
  font-size: 15px;
  border: none;
}
.messagesend::placeholder {
  color: #b25634;
  opacity: 0.7;
}
.messagesend:focus {
  padding: 0;
  color: #745140;
  padding: 7px 0px 7px;
  font-size: 15px;
  text-align: start;
  outline: none;
  border: none;
}
.chatava {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d6b7a8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.student_msg {
  height: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
}
.words_student {
  background-color: #c49483;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 15px;
  margin-left: 10px;
  border-radius: 7px;
  color: #fdfafe;
}
.words {
  background-color: #d6b7a8;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 15px;
  border-radius: 7px;
  color: #745140;
}
.sendword {
  font-family: 'Lora';
  position: fixed;
  bottom: 20px;
  right: 40px;
  color: #b25634;
  letter-spacing: 0.5px;
  font-size: 15px;
  border: none;
  cursor: pointer;
}
.contextdropdownmenu {
  width: 83px;
  position: absolute;
  background-color: #fdfafe;
  padding: 10px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  cursor: pointer;
  color: #b17b67;
  font-size: 15px;
  transition: 150ms;
  text-align: left;
}
.contextdropdownmenu:hover {
  background-color: whitesmoke;
}
.msg_time {
  margin-right: 15px;
  color: gray;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.msg_time_student {
  margin-left: 15px;
  color: gray;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.teacher_msg {
  height: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: flex-end;
}
@media screen and (max-width: 900px) {
  .chathistory {
    width: 0;
    display: none;
  }
  .chatcontent {
    width: 100%;
  }
  .chatname {
    width: 100%;
  }
  .send {
    width: 100%;
  }
}

.faqwrap {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;
}
.faqtitle {
  color: #b17b67;
  font-family: 'Playfair Display';
  font-size: 90px;
  margin: 50px;
  padding: 100px;
  text-align: center;
  margin-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
}
.tutorialinksbottom {
  background-color: #d6b7a8;
  color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 40px;
  width: wrap;
  margin-top: 15px;
  cursor: pointer;
  transition: 150ms;
}
.tutorialinksbottom:hover {
  background-color: #c49483;
}
.tutoriallinkswrap {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  column-gap: 20px;
}
.linkclickable {
  text-decoration: underline;
  cursor: pointer;
}
#answerlink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.faqintro {
  color: #745140;
  text-align: center;
  font-size: 23px;
  padding: 100px;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 250px;
}
.qabackground {
  background-color: #b17b67;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qawrap {
  background-color: #f6f1ef;
  width: 80%;
  margin-bottom: 150px;
  margin-top: -150px;
  display: flex;
  padding: 100px;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.faqimage {
  margin-top: 50px;
  width: 50vw;
  height: 100%;
  margin-bottom: 50px;
}
#googleaccount {
  width: 20vw;
}
#imgstudentsattendance {
  margin-bottom: 0px;
}
.qatitle {
  color: #b17b67;
  font-size: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
}
.emphasis {
  color: #d69d88;
  font-size: 70px;
}
.question {
  color: #745140;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.answer {
  font-size: 16px;
  color: #b17b67;
  margin-bottom: 7px;
  margin-top: 7px;
}
.linkqa {
  color: #d69d88;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 5px;
  margin-top: 5px;
  width: wrap;
}
.qaprocedure {
  color: #d69d88;
  margin-bottom: 5px;
  margin-top: 5px;
}
.questionwrap {
  margin-bottom: 30px;
}
.linkwrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1080px) {
  .faqtitle {
    font-size: 70px;
    padding: 70px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .faqintro {
    font-size: 20px;
    padding: 80px;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 250px;
  }
  .qatitle {
    font-size: 40px;
  }
  .emphasis {
    font-size: 60px;
  }
  .qawrap {
    padding: 60px;
  }
}
@media screen and (max-width: 780px) {
  #answerlink {
    flex-direction: column;
    align-items: flex-start;
  }
  .qatitle {
    flex-direction: column;
  }

  .tutoriallinkswrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.outerwrapstudy {
  margin-top: 80px;
  height: wrap;
  width: wrap;
  background-color: #efe9e5;
  display: flex;
  flex-direction: row;
  padding: 40px;
  padding-top: 70px;
  padding-bottom: 200px;
}
.linkstyle {
  color: #745140;
  text-decoration: underline;
  margin-bottom: 5px;
  cursor: pointer;
}
.passcodes {
  color: #745140;
  margin-bottom: 10px;
  font-size: 13px;
}
.filterwrap {
  width: 30%;
}
.filter {
  padding-right: 30px;
  margin: 0;
  margin-bottom: 50px;
}
.studymatsecondwrap {
  width: 70%;
}
.studysubject {
  height: 100px;
  cursor: pointer;
  margin: 40px;
  width: 100px;
  transition: transform 0.3s;
}
.studysubject:hover {
  transform: scale(1.1);
}

#studydialog {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  font-weight: 700;
  font-size: 22px;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 10px;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
#warningdialogstudy {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: darkred;
  font-weight: 400;
  font-size: 17px;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
}
.titlestudytotal {
  color: #745140;
  font-size: 50px;
}
#links {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #745140;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  height: wrap;
  padding-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}
.dotforstudy {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #745140;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  height: wrap;
  padding-top: 10px;
  text-decoration: none;
}
.wrapperlinks {
  background-color: whitesmoke;
  overflow: block;
  height: wrap;
  width: wrap;
  padding: 30px;
  padding-top: 5px;
}
.wordlinkwrapping {
  display: flex;
  flex-direction: row;
}
.studysubjectwrap {
  height: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.titleandimg {
  height: wrap;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.titlesubjectwrap {
  height: wrap;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 70px;
}
.subjectwords {
  width: 100%;
  text-align: center;
  color: #745140;
  font-size: 20px;
}
.studymatwrap {
  height: wrap;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.studymattitle {
  margin-top: 30px;
  color: #b25634;
  font-size: 30px;
  font-weight: 400;
}

@media screen and (max-width: 560px) {
  .titlestudytotal {
    text-align: center;
    width: 100%;
    color: #745140;
    font-size: 40px;
  }
  .studymatsecondwrap {
    width: 100%;
  }
}

.publisher {
  color: #745140;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 10px;
}
.schoolstudy {
  margin-bottom: 17px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}
.schoollist {
  font-weight: 400;
  background-color: #b17b67;
  border-radius: 3px;
  color: #efe9e5;
  font-size: 15px;
  margin-right: 10px;
  padding: 5px 10px;
}
.tagswrap {
  width: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: 40px;
}
.tagsstudy {
  background-color: #efe9e5;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 13px;
  color: #b17b67;
  margin-right: 10px;
}
.studymatbox {
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.openfilestudy {
  font-family: 'Lora';
  width: wrap;
  height: wrap;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  background-color: white;
  color: #b17b67;
  letter-spacing: 0.5px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #b17b67;
  transition: 250ms;
}

.openfilestudy:hover {
  background: #b17b67;
  border: 1px solid #b17b67;
  color: white;
}

.linkstudy {
  text-decoration: none;
}
.filtersub {
  color: #b25634;
  padding-top: 20px;
  padding-bottom: 20px;
}
.filterline {
  color: #dbbfb1;
  margin-bottom: 20px;
}
.spacing {
  height: 20px;
}
.filtertitle {
  color: #745140;
  font-size: 20px;
}

@media screen and (max-width: 960px) {
  .studymatsecondwrap {
    width: 100%;
  }
  .studymattitle {
    margin-top: 30px;
    color: #b25634;
    font-size: 35px;
    font-weight: 400;
    width: 100%;
    text-align: center;
  }
  .studysubjectwrap {
    height: wrap;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .titlestudytotal {
    text-align: center;
    width: 100%;
    color: #745140;
    font-size: 50px;
  }
  .studysubject {
    height: 150px;
    cursor: pointer;
    margin: 40px;
    width: 150px;
  }
  .subjectwords {
    width: 100%;
    text-align: center;
    color: #745140;
    font-size: 25px;
  }
  .filter {
    display: none;
    width: 0;
  }
}

