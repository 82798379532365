.about_uscont {
  padding-top: 60px;
  background-color: #efe9e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: wrap;
}
.about_ustitle {
  color: #745140;
  font-size: 50px;
}
.traitwrap {
  display: flex;
  padding: 50px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: end;
  padding-bottom: 0px;
}
.about_ustrait {
  padding-left: 40px;
  padding-right: 40px;
}
.traitename {
  color: #745140;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  float: bottom;
}

.traitcontent {
  color: #745140;
  text-align: justify;
  margin-top: 20px;
  font-size: 17px;
}
.about_usline {
  line-height: 5px;
  background-color: #c49483;
}

@media screen and (max-width: 1080px) {
  .traitename {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .about_ustrait {
    padding-left: 20px;
    padding-right: 20px;
  }
  .traitcontent {
    margin-top: 20px;
    font-size: 16px;
  }
}
@media screen and (max-width: 868px) {
  .traitwrap {
    display: flex;
    padding: 50px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: end;
    padding-bottom: 0px;
  }
  .traitename {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .about_ustrait {
    padding-left: 50px;
    padding-right: 50px;
  }
  .traitcontent {
    margin-top: 20px;
    margin-bottom: 100px;
    font-size: 16px;
  }
}
