#homePageTitle {
	letter-spacing: 8px;
	text-align: center;
	color: #b17b67;
	font-size: 60px;
	align-self: center;
}

.loggedincontainer {
	height: 90vh;
	width: wrap;
	display: flex;
	background-color: #efe9e5;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 80px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: auto;
	object-fit: contain;
}

.btn-hero:hover {
	background: #b17b67;
	color: #efe9e5;
	transition: 250ms;
}

.btn-hero {
	color: #b17b67;
	font-family: 'Lora';
	width: 240px;
	text-decoration: none;
	margin: 10px;
	cursor: pointer;
	letter-spacing: 0.5px;
	font-size: 20px;
	text-align: center;
	border: 1px solid #b17b67;
	padding: 8px 20px;
}

#loggedintitle {
	letter-spacing: 10px;
	text-align: center;
	font-size: 50px;
	color: #b17b67;
	align-self: center;
}

#loggedinsub {
	padding-right: 25px;
	padding-left: 25px;
	font-size: 20px;
	color: #745140;
	margin-top: 50px;
	letter-spacing: 1px;
	text-align: center;
}

.btn-mobile {
	display: flex;
	text-decoration: none;
}

.pic_hero {
	width: 30vw;
}

#homePageSubTitle {
	letter-spacing: 1px;
	text-align: center;
	color: #745140;
	align-self: center;
	font-size: 20px;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 20px;
}

.hero-container {
	height: 90vh;
	width: wrap;
	display: flex;
	background-color: #efe9e5;
	flex-direction: row;
	margin-top: 80px;
}

.left_hero {
	width: 40%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.right_hero {
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.hero-btns {
	margin-top: 25px;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.hero-btns .btn-hero {
	margin: 20px;
	width: 200px;
	text-align: center;
	font-size: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 60px;
}

@media screen and (max-width: 960px) {
	#homePageSubTitle {
		font-size: 18px;
		line-height: 25px;
	}

	#homePageTitle {
		font-size: 30px;
		letter-spacing: 8px;
	}

	#loggedinsub {
		font-size: 16px;
		line-height: 25px;
	}

	.hero-btns {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.hero-btns .btn-hero {
		margin: 10px;
		height: 38px;
		width: 200px;
		font-size: 17px;
	}

	#loggedintitle {
		font-size: 30px;
		letter-spacing: 8px;
	}
}

@media screen and (max-width: 768px) {
	.left_hero {
		width: 0px;
		display: none;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.right_hero {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	.btn-mobile {
		display: block;
		text-decoration: none;
	}

	.hero-btns {
		margin-top: 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.hero-btns .btn-hero {
		font-size: 20px;
		margin: 10px;
		width: 200px;
		height: 45px;
	}

	#loggedintitle {
		font-size: 50px;
		letter-spacing: 8px;
	}

	#loggedinsub {
		font-size: 25px;
		margin-bottom: 35px;
	}

	#homePageSubTitle {
		font-size: 25px;
		margin-bottom: 35px;
	}

	#homePageTitle {
		font-size: 50px;
		letter-spacing: 8px;
	}
}