.outerwrapstudy {
  margin-top: 80px;
  height: wrap;
  width: wrap;
  background-color: #efe9e5;
  display: flex;
  flex-direction: row;
  padding: 40px;
  padding-top: 70px;
  padding-bottom: 200px;
}
.linkstyle {
  color: #745140;
  text-decoration: underline;
  margin-bottom: 5px;
  cursor: pointer;
}
.passcodes {
  color: #745140;
  margin-bottom: 10px;
  font-size: 13px;
}
.filterwrap {
  width: 30%;
}
.filter {
  padding-right: 30px;
  margin: 0;
  margin-bottom: 50px;
}
.studymatsecondwrap {
  width: 70%;
}
.studysubject {
  height: 100px;
  cursor: pointer;
  margin: 40px;
  width: 100px;
  transition: transform 0.3s;
}
.studysubject:hover {
  transform: scale(1.1);
}

#studydialog {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  font-weight: 700;
  font-size: 22px;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 10px;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
#warningdialogstudy {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: darkred;
  font-weight: 400;
  font-size: 17px;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
}
.titlestudytotal {
  color: #745140;
  font-size: 50px;
}
#links {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #745140;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  height: wrap;
  padding-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}
.dotforstudy {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #745140;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  height: wrap;
  padding-top: 10px;
  text-decoration: none;
}
.wrapperlinks {
  background-color: whitesmoke;
  overflow: block;
  height: wrap;
  width: wrap;
  padding: 30px;
  padding-top: 5px;
}
.wordlinkwrapping {
  display: flex;
  flex-direction: row;
}
.studysubjectwrap {
  height: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.titleandimg {
  height: wrap;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.titlesubjectwrap {
  height: wrap;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 70px;
}
.subjectwords {
  width: 100%;
  text-align: center;
  color: #745140;
  font-size: 20px;
}
.studymatwrap {
  height: wrap;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.studymattitle {
  margin-top: 30px;
  color: #b25634;
  font-size: 30px;
  font-weight: 400;
}

@media screen and (max-width: 560px) {
  .titlestudytotal {
    text-align: center;
    width: 100%;
    color: #745140;
    font-size: 40px;
  }
  .studymatsecondwrap {
    width: 100%;
  }
}

.publisher {
  color: #745140;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 10px;
}
.schoolstudy {
  margin-bottom: 17px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}
.schoollist {
  font-weight: 400;
  background-color: #b17b67;
  border-radius: 3px;
  color: #efe9e5;
  font-size: 15px;
  margin-right: 10px;
  padding: 5px 10px;
}
.tagswrap {
  width: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: 40px;
}
.tagsstudy {
  background-color: #efe9e5;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 13px;
  color: #b17b67;
  margin-right: 10px;
}
.studymatbox {
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.openfilestudy {
  font-family: 'Lora';
  width: wrap;
  height: wrap;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  background-color: white;
  color: #b17b67;
  letter-spacing: 0.5px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #b17b67;
  transition: 250ms;
}

.openfilestudy:hover {
  background: #b17b67;
  border: 1px solid #b17b67;
  color: white;
}

.linkstudy {
  text-decoration: none;
}
.filtersub {
  color: #b25634;
  padding-top: 20px;
  padding-bottom: 20px;
}
.filterline {
  color: #dbbfb1;
  margin-bottom: 20px;
}
.spacing {
  height: 20px;
}
.filtertitle {
  color: #745140;
  font-size: 20px;
}

@media screen and (max-width: 960px) {
  .studymatsecondwrap {
    width: 100%;
  }
  .studymattitle {
    margin-top: 30px;
    color: #b25634;
    font-size: 35px;
    font-weight: 400;
    width: 100%;
    text-align: center;
  }
  .studysubjectwrap {
    height: wrap;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .titlestudytotal {
    text-align: center;
    width: 100%;
    color: #745140;
    font-size: 50px;
  }
  .studysubject {
    height: 150px;
    cursor: pointer;
    margin: 40px;
    width: 150px;
  }
  .subjectwords {
    width: 100%;
    text-align: center;
    color: #745140;
    font-size: 25px;
  }
  .filter {
    display: none;
    width: 0;
  }
}
