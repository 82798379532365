.outsidecontainerapp {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
#edittodos {
  color: #745140;
  width: 95%;
  margin-top: 20px;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  border: 1.5px solid rgba(178, 86, 52, 0.3);
}
.submissionlinkwrap {
  display: flex;
  margin-top: 20px;
  width: 95%;
}
#editlink {
  color: #745140;
  line-height: 20px;
  padding: 7px 0px 7px;
  font-size: 15px;
  border-radius: 0px;
  padding: 10px;
  border: none;
  flex-grow: 1;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
#editlink:focus {
  outline: none;
  border: none;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
#editlink::placeholder {
  font-size: 15px;
  opacity: 0.5;
  color: #745140;
}
.choosekid.active {
  z-index: 999;
  left: 0;
  transition: 350ms;
  background-color: #c49483;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.multi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.borderstudent {
  width: 100%;
  text-align: center;
  color: whitesmoke;
}
.choosekid {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  flex-direction: row;
  top: 100px;
  left: -65px;
  z-index: 999;
  transition: 350ms;
  background-color: #c49483;
  width: wrap;
  height: wrap;
  padding: 5px;
}
.kidicon {
  left: 0;
  color: whitesmoke;
}

.nokid {
  display: flex;
  height: 500px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.googlemeet {
  padding-left: 30px;
  padding-right: 30px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wraptodos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noStudentFont {
  color: #745140;
  font-size: large;
}
.noStudentFont2 {
  color: #745140;
  margin-top: 30px;
  font-size: 25px;
  font-weight: 700;
}
#edittodos::placeholder {
  font-size: 15px;
  opacity: 0.5;
  color: #745140;
}
#edittodos:focus {
  outline: none;
  color: #745140;
  border: 1.5px solid rgba(178, 86, 52, 0.3);
}
.con {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.googlemeet,
.agenda,
.task,
.notes,
.classdate,
.classduration {
  width: 80vw;
  margin: 20px;
  background-color: #efe9e5;
  border-radius: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.icon_app {
  color: #745140;
  height: 30px;
  width: 30px;
}
.image_app {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#app_succ {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: #745140;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 20px;
  padding: 20px;
}
#app_registeredsucc {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: #745140;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 20px;
  padding: 10px;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
}
#appointment_sendtitle {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: darkred;
  flex-direction: row;
  font-weight: 700;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 17px;
  padding: 10px;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
}
.appointment_content {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  white-space: pre-line;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 14px;
  padding-top: 5px;
  padding-right: 20px;
  padding-left: 40px;
  text-align: left;
}
.appointment_subtitles {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  text-align: center;
  color: #745140;
  flex-direction: row;
  font-weight: 700;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 16px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 30px;
}

#app_send {
  border-radius: 3px;
  background-color: #efe9e5;
  padding: 8px 20px;
  width: wrap;
  text-align: center;
  font-size: 15px;
  color: #b25634;
  border: 1px solid #b25634;
  font-family: 'Lora';
  width: 100px;
}
.wrapappointment {
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  justify-content: end;
  align-items: center;
  padding-top: 50px;
}
#app_send:hover {
  transition: all 0.3s ease-out;
  background: #b25634;
  color: whitesmoke;
  transition: 250ms;
  border: 1px solid #b25634;
  cursor: pointer;
}
#app_return {
  background-color: whitesmoke;
  font-weight: 700;
  color: #b25634;
  font-size: 15px;
  width: 100%;
  text-align: center;
  padding: 10px;
}
.agenda,
.task,
.notes,
.classdate,
.classduration {
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}
.image_app {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #d6b7a8;
}

.meet {
  border-radius: 3px;
  border-color: transparent;
  background-color: #d6b7ab;
  padding: 8px 30px;
  font-size: 20px;
  color: #fbf5ed;
  font-family: 'Lora';
}

.meet:hover {
  transition: all 0.3s ease-out;
  background: #745140;
  color: #e1d7d2;
  transition: 250ms;
  cursor: pointer;
}
.buttonwrapapp {
  margin-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.sendapp {
  border-radius: 3px;
  border-color: transparent;
  background-color: #d6b7ab;
  padding: 8px 30px;
  font-size: 20px;
  margin-bottom: 50px;
  width: fit-content;
  color: #fbf5ed;
  font-family: 'Lora';
}

.sendapp:hover {
  transition: all 0.3s ease-out;
  background: #745140;
  color: #e1d7d2;
  transition: 250ms;
  cursor: pointer;
}
.namesection_app {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 30px;
  line-height: 30px;
}
.app_name {
  font-size: 20px;
  color: #b17b67;
}
.hours {
  font-size: 13px;
  color: #5a7184;
}
.app_title {
  color: #b25634;
  font-size: 25px;
  padding-bottom: 20px;
  padding-left: 20px;
}
.submissiontitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #745140;
}
.app_line {
  width: 80vw;
  background-color: #c49483;
  opacity: 0.5;
  height: 3px;
}
#editdate {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  color: #745140;
  height: 30px;
  font-weight: 400;
  letter-spacing: 0.8px;
  border: none;
  font-size: 15px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
.appwrap {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.datedatewrap {
  width: 100%;
}
.editrest {
  color: #745140;
  height: 30px;
  font-weight: 400;
  width: 100%;
  letter-spacing: 0.8px;
  border: none;
  font-size: 15px;
  border-bottom: 1.5px solid rgba(178, 86, 52, 0.3);
}
#editdate:focus {
  border: none;
  color: #745140;
  outline: none;
  border-bottom: 1.5px solid #c49483;
}
#editdate::placeholder {
  font-size: 15px;
  opacity: 0.5;
  color: #745140;
}
