#outcontainer {
  margin-top: 80px;
  height: fit-content;
}
#container {
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}
#bookingprogramdia {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  font-weight: 700;
  color: #b25634;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
.book_button_wrap {
  width: 100%;
}
#favoritetitle {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  font-weight: 700;
  color: #b25634;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: wrap;
  padding: 15px;
  text-align: center;
}
.book_button_wrap {
  background-color: #f5f5f5;
}
.book_title {
  align-items: center;
  background-color: #f5f5f5;
  color: #b25634;
  justify-content: center;
  position: relative;
  text-align: center;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 20px;
}
.book_outside_wrap {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: wrap;
  width: 100%;
  padding: 30px;
  padding-top: 5px;
}
.book_button {
  align-items: center;
  background-color: #f5f5f5;
  color: #b25634;
  display: flex;
  flex-direction: row;
  border: 1px solid #b25634;
  height: wrap;
  justify-content: center;
  border-radius: 10px;
  overflow: block;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 15px;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: 150ms;
}
.book_button:hover {
  background-color: #b25634;
  color: #f5f5f5;
}
#bookingprogramdia_sub {
  background-color: whitesmoke;
  overflow: block;
  position: relative;
  display: flex;
  color: #b25634;
  flex-direction: row;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  height: wrap;
  font-size: 14px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
#cardbox {
  background-color: #efe9e5;
  height: 100%;
  position: relative;
  padding-bottom: 10px;
}
#topic {
  font-family: 'Lora';
  color: #212324;
}
#subtopic {
  font-family: 'Lora';
  color: #4a7d76;
}

#add {
  position: absolute;
  font-family: 'Lora';
  color: #b25634;
  margin-bottom: 5px;
  bottom: 0px;
}
.heart {
  color: #b25634;
  margin-right: 3px;
}

#image {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.2s linear;
}
#wrap {
  padding: 30px;
  height: 300px;
  overflow: block;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}
#innerwrap {
  position: absolute;
  padding-right: 30px;
  bottom: 20px;
}
#dialog_topic,
#dialog_subtitle {
  color: #fdfaf1;
  font-family: 'Lora';
}

#gridcontainer,
#fav_gridcontainer {
  height: fit-content;
  padding-bottom: 100px;
}
#dialog_topic {
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 10px;
}
#book {
  color: #fdfaf1;
  font-family: 'Lora';
  background-color: #60a399;
  padding-right: 7px;
  padding-left: 7px;
}

#buttonwrap {
  padding-top: 15px;
}
#dialog_add {
  margin-left: 15px;
  color: #fdfaf1;
  font-family: 'Lora';
  background-color: #eba68a;
  padding-right: 7px;
  padding-left: 7px;
}
.heart_dialog {
  color: #fdfaf1;
  margin-right: 3px;
}
#lowerwrap {
  padding: 30px;
  background-color: #e1d7d2;
}
#loc {
  display: flex;
  flex-direction: row;
}
.loc_dialog {
  padding: 0;
  color: #b25634;
  font-size: 210%;
}
#position {
  font-size: 15px;
  margin-left: 10px;
  color: #485861;
  font-weight: 600;
}
#subject {
  width: 30%;
  padding-left: 15px;
  font-weight: 500;
  padding-top: 3px;
  font-size: 15px;
  color: #4a7d76;
}
#content {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
}
#school_content {
  width: 70%;
  font-size: 12.5px;
  color: #625f5f;
  line-height: 23px;
}
#misc {
  font-size: 10px;
  width: 30%;
  padding-left: 15px;
}
.basicinfo {
  margin-top: 3px;
}
#date_topic {
  color: #b25634;
  font-size: 12px;
}
#coordinator_topic,
#target_topic {
  margin-top: 9px;
  color: #49968c;
  font-size: 12px;
}
#word {
  margin-top: 80px;
}
#location_inner {
  width: 70%;
  display: flex;
  flex-direction: row;

  align-items: center;
}
